import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useIsMobile = () => {
  const {breakpoints} = useTheme()

  return useMediaQuery(breakpoints.down('sm'))
}

export default useIsMobile
