import classNames from 'classnames'
import React from 'react'

import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const CircleBadge = ({jobRecordCount, position}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const mobilePosition = {
    left: -15,
    margin: 0,
  }

  return (
    <div
      style={isMobile ? mobilePosition : {}}
      className={classNames(classes.container, {
        [classes.show]: jobRecordCount,
        [classes.initialPosition]: position === 'initial',
      })}
    >
      <span className={classes.content}>{jobRecordCount}</span>
    </div>
  )
}

export default CircleBadge
