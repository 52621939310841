import {useEffect, useState} from 'react'

const useMountComponent = () => {
  const [layout, setLayout] = useState(false)

  useEffect(() => {
    setLayout(true)
  }, [])

  return layout
}

export default useMountComponent
