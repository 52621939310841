import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, palette, spacing}) => ({
  isNowrap: {
    whiteSpace: 'nowrap',
  },
  link: {
    padding: 0,
    textDecoration: 'none',
    display: 'block',
  },
  roundedButton: {
    boxShadow: 'none',
    padding: `${spacing(1.8)} ${spacing(2.3)}`,
    boxSizing: 'border-box',
    textTransform: 'none',
    fontFamily: 'CamptonBook',
    // fontSize: '1.12em',
    borderRadius: 35,
    height: 'min-content',
    '& > span > span': {
      lineHeight: spacing(3),
    },
    [breakpoints.up('sm')]: {
      fontSize: '1.12em',
    },
    [breakpoints.down('sm')]: {
      width: 'inherit',
    },
  },
  primary: {
    color: palette.primary.main,
    backgroundColor: `${palette.primary.main} !important`,
    border: palette.primary.main,
  },
  secondary: {
    color: palette.secondary.main,
    backgroundColor: `${palette.secondary.main} !important`,
    border: palette.secondary.main,
  },
  tertiary: {
    color: palette.tertiary.main,
    backgroundColor: `${palette.tertiary.main} !important`,
    border: palette.tertiary.main,
  },
  grey: {
    color: palette.tertiary.main,
    backgroundColor: `${palette.background.gray} !important`,
    border: palette.background.gray,
  },
  centered: {
    textAlign: 'center',
    margin: '0 auto',
    display: 'flex',
  },
  buttonLabel: {
    marginRight: spacing(1.5),
    alignItems: 'center',
    display: 'flex',
  },
  buttonArrow: {
    transform: 'rotate(225deg)',
  },
  buttonArrowDown: {
    marginLeft: spacing(1),
    transform: 'rotate(315deg)',
    color: palette.secondary.main,
  },
  contentButtonArrowDown: {
    transform: 'rotate(315deg)',
  },
  selectIcon: {
    marginRight: spacing(4),
    marginLeft: spacing(-6),
    zIndex: '-1',
  },
  buttonIcon: {
    paddingLeft: spacing(1),
  },
  bordered: {
    borderStyle: 'solid',
    background: 'transparent!important',
    borderWidth: '1px',
    minWidth: 'fit-content',
    '&:hover': {
      backgroundColor: `${palette.primary.main}!important`,
      color: `${palette.text.light}!important`,
      borderColor: 'transparent!important',
    },
  },
  borderedSecondary: {
    '&:hover': {
      backgroundColor: `${palette.secondary.main}!important`,
      color: `${palette.text.light}!important`,
      borderColor: 'transparent!important',
    },
  },
  filled: {
    color: `${palette.text.light}`,
  },
  notClickable: {
    cursor: 'default',
    '&:hover': {
      boxShadow: 'inherit',
    },
  },
  isSmallText: {
    fontSize: '1em',
  },
  isDownload: {
    color: palette.secondary.main,
    '&:hover': {
      '& svg': {
        color: 'white !important',
      },
    },
  },
  isBold: {
    fontFamily: 'CamptonSemiBold',
  },
  isContact: {
    padding: `${spacing(1.8)} ${spacing(0.8375)}`,
  },
  isReversed: {
    color: `${palette.text.light}`,
    '&:hover': {
      color: `${palette.primary.main}`,
      backgroundColor: 'white !important',
      borderStyle: 'solid',
      background: 'transparent!important',
      borderWidth: '1px',
    },
  },
  outlined: {
    border: `1px solid ${palette.primary.main}`,
    backgroundColor: 'transparent!important',
    color: palette.primary.main,
  },
}))

export default useStyles
