import {useEffect} from 'react'
import AOS from 'aos'

import 'aos/dist/aos.css'

export default boolean => {
  useEffect(() => {
    AOS.init({
      once: process.env.NODE_ENV === 'production',
    })
    AOS.refresh()
  }, [boolean])
}
