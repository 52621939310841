import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette}) => ({
  container: {
    position: 'absolute',
    top: -7,
    right: -18,
    width: 21,
    height: 21,
    borderRadius: '100%',
    background: palette.secondary.main,
    color: 'white',
    fontSize: 11,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    boxShadow:
      '0px 1px 2px -1px rgba(0,0,0,0.1), 0px 2px 3px 0px rgba(0,0,0,0.08), 0px 0px 5px 0px rgba(0,0,0,0.06)',
    opacity: 0,
    transform: 'scale(0)',
    transition: 'opacity 0.3s ease, transform 0.3s ease',
  },
  content: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
    textAlign: 'center',
    lineHeight: '19px',
  },
  show: {
    opacity: 1,
    transform: 'scale(1)',
  },
  initialPosition: {
    position: 'initial',
  },
}))

export default useStyles
