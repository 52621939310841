module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5K6PST9","includeInDevelopment":false,"timeout":2500,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"UbHhL1LGxQeMPH3gWcP9Tw","gtmPreview":"env-17"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#28c9ff","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
