import * as R from 'ramda'
import {bool, node, number, oneOf, shape, string} from 'prop-types'
import classNames from 'classnames'
import React from 'react'
import Typography from '@mui/material/Typography'

import {parseText} from 'helpers/utils'

import useStyles from './styles'

const Description = ({
  children,
  color,
  isCentered,
  hasMarginTop,
  hasMarginBottom,
  type,
  isJustified,
  isItalic,
  animation,
}) => {
  const classes = useStyles()
  const animationAos = R.pathOr('', ['animation'], animation)
  const animationDelay = R.pathOr('', ['delay'], animation)
  const animationDuration = R.pathOr('', ['duration'], animation)

  const renderChildren = childrenArray =>
    childrenArray.map((child, index) => (
      <React.Fragment key={index}>{parseText(child)}</React.Fragment>
    ))

  return (
    <Typography
      data-aos={animationAos}
      data-aos-delay={animationDelay}
      data-aos-duration={animationDuration}
      className={classNames(classes.description, classes.preLine, {
        [classes.marginTop]: hasMarginTop,
        [classes.marginBottom]: hasMarginBottom,
        [classes.centered]: isCentered,
        [classes.largeDescription]: type === 'largeDescription',
        [classes.classicDescription]: type === 'description',
        [classes.classicDescription]: type === 'paragraph',
        [classes.colorWhite]: color === 'white',
        [classes.justify]: isJustified,
        [classes.italic]: isItalic,
      })}
    >
      {Array.isArray(children) ? renderChildren(children) : parseText(children)}
    </Typography>
  )
}

Description.propTypes = {
  animation: shape({
    animation: string,
    delay: number,
    duration: number,
    offset: number,
    direction: string,
  }),
  children: node,
  color: string,
  hasMarginBottom: bool,
  hasMarginTop: bool,
  isCentered: bool,
  isItalic: bool,
  isJustified: bool,
  type: string,
}

Description.defaultProps = {
  animation: null,
  children: '',
  color: '',
  hasMarginBottom: false,
  hasMarginTop: false,
  isCentered: false,
  isItalic: false,
  isJustified: false,
  type: 'description',
}

export default Description
