import * as R from 'ramda'
import {mapOrder} from 'helpers/utils'

export const getServices = (data, topLevelServices) => {
  const orderTop = R.map(el => el.entryId, topLevelServices)

  const getTop = () => {
    // eslint-disable-next-line fp/no-let
    let arr = []
    R.map(
      ({node}) =>
        R.map(entry => {
          if (node.contentful_id === entry.entryId) {
            arr = [...arr, node]
          }

          return null
        }, topLevelServices),
      data.allContentfulService.edges,
    )

    return mapOrder(arr, orderTop, 'contentful_id')
  }

  R.map(obj => {
    R.map(entry => {
      if (entry.entryId === obj.contentful_id) {
        // eslint-disable-next-line no-param-reassign
        obj.subLevelServicesIds = entry.subLevelServices
      }

      return null
    }, topLevelServices)

    return null
  }, getTop())

  R.map(obj => {
    const orderSub = R.map(el => el.entryId, obj.subLevelServicesIds)

    // eslint-disable-next-line fp/no-let
    let arr = []

    R.map(
      ({node}) =>
        R.map(o => {
          if (node.contentful_id === o.entryId) {
            arr = [...arr, node]
            // eslint-disable-next-line no-param-reassign
            obj.subLevelServices = mapOrder(arr, orderSub, 'contentful_id')
          }

          return null
        }, obj.subLevelServicesIds),
      data.allContentfulService.edges,
    )

    return null
  }, getTop())

  return getTop()
}
