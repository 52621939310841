import {Link} from 'gatsby'
import {string} from 'prop-types'
import React from 'react'

import RoundButton from 'components/UI/RoundButton'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const ContactUsComponent = ({
  contactUsLink,
  contactUsTitle,
  findAJobLink,
  findAJobTitle,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <>
      {!isMobile && (
        <div className={classes.wrapper}>
          <Link to={contactUsLink}>{contactUsTitle}</Link>
          <a target="_blank" href={findAJobLink} rel="noreferrer">
            {findAJobTitle}
          </a>
        </div>
      )}
      {isMobile && (
        <div className={classes.mobileWrapper}>
          <div className={classes.navigationItem}>
            <RoundButton
              color="primary"
              centered
              isSmallText
              isFilled={false}
              href={contactUsLink}
              isNotBold
            >
              {contactUsTitle}
            </RoundButton>
          </div>
          <div className={classes.navigationItem}>
            <RoundButton
              color="primary"
              centered
              isSmallText
              isReversed
              href={findAJobLink}
              isNotBold
            >
              {findAJobTitle}
            </RoundButton>
          </div>
        </div>
      )}
    </>
  )
}

ContactUsComponent.propTypes = {
  contactUsLink: string,
  contactUsTitle: string,
  findAJobLink: string,
  findAJobTitle: string,
}
ContactUsComponent.defaultProps = {
  contactUsLink: '',
  contactUsTitle: '',
  findAJobLink: '',
  findAJobTitle: '',
}

export default ContactUsComponent
