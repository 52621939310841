import * as R from 'ramda'
import {bool, func, node, number, oneOf, shape, string} from 'prop-types'
import {Link} from 'gatsby'
import {saveAs} from 'file-saver'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import CachedIcon from '@mui/icons-material/Cached'
import classNames from 'classnames'
import CtaArrowIcon from '@mui/icons-material/CallReceived'
import React from 'react'
import useStyles from './styles'

const RoundButton = ({
  children,
  color,
  arrow,
  arrowDown,
  downloadArrow,
  refresh,
  href,
  fileName,
  isCentered,
  action,
  isAction,
  isClickable,
  isFilled,
  isSmallText,
  onMouseIn,
  onMouseOut,
  externalLink,
  isDownload,
  isNowrap,
  isNotBold,
  isContact,
  state,
  animation,
  isReversed,
  isFilledForce,
  contentArrowDirection,
  variation,
}) => {
  const classes = useStyles()
  const animationAos = R.pathOr('', ['animation'], animation)
  const animationDelay = R.pathOr('', ['delay'], animation)

  const saveFile = (url, name) => {
    saveAs(url, name)
  }

  const MuiButton = () => (
    <Button
      data-aos={animationAos}
      data-aos-delay={animationDelay}
      variant={variation === 'Outlined' ? 'outlined' : 'contained'}
      color={color}
      className={classNames(classes.roundedButton, {
        [classes.isReversed]: isReversed,
        [classes.isContact]: isContact,
        [classes.isBold]: !isNotBold,
        [classes.filled]: (!isAction && isFilled) || isFilledForce,
        [classes.primary]: color === 'primary',
        [classes.secondary]: color === 'secondary',
        [classes.tertiary]: color === 'tertiary',
        [classes.grey]: color === 'grey',
        [classes.centered]: isCentered,
        [classes.bordered]:
          !isFilledForce && (isAction || isDownload || !isFilled),
        [classes.borderedSecondary]:
          (isAction && color === 'secondary') ||
          isDownload ||
          color === 'secondary',
        [classes.notClickable]: !isClickable,
        [classes.isSmallText]: isSmallText,
        [classes.isDownload]: isDownload,
        [classes.isNowrap]: isNowrap,
        [classes.isFilledForce]: isFilledForce,
        [classes.outlined]: variation === 'Outlined',
      })}
      onClick={isDownload ? () => saveFile(href, fileName) : action}
      onMouseEnter={onMouseIn}
      onMouseLeave={onMouseOut}
    >
      {children ? (
        <span
          className={
            arrow || contentArrowDirection ? classes.buttonLabel : null
          }
        >
          {children}
        </span>
      ) : null}
      {arrow && <CtaArrowIcon className={classes.buttonArrow} />}
      {arrowDown && <ArrowDownIcon className={classes.buttonIcon} />}
      {downloadArrow && <CtaArrowIcon className={classes.buttonArrowDown} />}
      {contentArrowDirection && (
        <CtaArrowIcon
          className={classNames({
            [classes.contentButtonArrowDown]: contentArrowDirection === 'Down',
            [classes.buttonArrow]: contentArrowDirection === 'Right',
          })}
        />
      )}
      {refresh && <CachedIcon className={classes.buttonIcon} />}
    </Button>
  )

  return (
    <>
      {isAction && isFilled && <MuiButton />}
      {!isAction && !externalLink && (
        <Link
          to={href}
          state={state && state}
          className={classNames(classes.link, {
            [classes.centered]: isCentered,
          })}
        >
          <MuiButton />
        </Link>
      )}
      {!isAction && externalLink && (
        <a
          className={classNames(classes.link, {
            [classes.centered]: isCentered,
          })}
          target="_blank"
          rel="noopener noreferrer"
          href={href}
        >
          <MuiButton />
        </a>
      )}
    </>
  )
}

RoundButton.propTypes = {
  action: func,
  animation: shape({
    animation: string,
    delay: number,
    duration: number,
    offset: number,
    direction: string,
  }),
  arrow: bool,
  arrowDown: bool,
  children: node,
  color: oneOf(['primary', 'secondary', 'tertiary', 'grey']),
  downloadArrow: bool,
  externalLink: bool,
  fileName: string,
  href: string,
  isAction: bool,
  isCentered: bool,
  isClickable: bool,
  isContact: bool,
  isDownload: bool,
  isFilled: bool,
  isFilledForce: bool,
  isNotBold: bool,
  isNowrap: bool,
  isReversed: bool,
  isSmallText: bool,
  onMouseIn: func,
  onMouseOut: func,
  refresh: bool,
  state: node,
  variation: string,
}

RoundButton.defaultProps = {
  action: null,
  animation: null,
  arrow: false,
  arrowDown: false,
  children: '',
  color: 'primary',
  downloadArrow: false,
  externalLink: false,
  fileName: '',
  href: '#',
  isAction: false,
  isCentered: false,
  isClickable: true,
  isContact: false,
  isDownload: false,
  isFilled: true,
  isFilledForce: false,
  isNotBold: false,
  isNowrap: false,
  isReversed: false,
  isSmallText: false,
  onMouseIn: null,
  onMouseOut: null,
  refresh: false,
  state: {},
  variation: '',
}

export default RoundButton
