import {useEffect, useState} from 'react'

export default function useStaticQueryImport(importPath, staticQueryHook) {
  const [moduleData, setModuleData] = useState(null)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    importPath
      .then(res => res[staticQueryHook])
      .then(dat => {
        setModuleData(dat)
      })
      .catch(err => setError(err.message))
  }, [])

  useEffect(() => {
    if (moduleData) {
      setData(moduleData)
    }
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }, [moduleData])

  return [data]
}
