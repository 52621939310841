import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({layout, palette}) => ({
  buttonClose: {
    marginRight: '5vw',
    height: layout.header.buttonClose.height,
    width: layout.header.buttonClose.width,
    position: 'relative',
    left: layout.header.buttonClose.left,
    top: layout.header.buttonClose.top,
    cursor: 'pointer',
    '&:hover': {
      color: palette.primary.main,
    },
  },
}))

export default useStyles
