/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
import {string} from 'prop-types'
import React, {createRef, useState} from 'react'

import useStaticQueryImport from 'hooks/useStaticQueryImport'

import LocalSearch from './LocalSearchKm'
import SearchMenuItem from './SearchMenuItem/index'

const Search = ({
  country,
  navLocale,
  isMultiLang,
  searchPlaceholder,
  countrySubServices,
  pageData,
}) => {
  const [hasFocus, setFocus] = useState(false)
  const SearchMenuItemRef = createRef()

  const [data] =
    process.env.GATSBY_WEBSITE === 'kls'
      ? useStaticQueryImport(
          import(
            '../../../../keyrus-life-science/src/hooks/useSearchPublicUrls'
          ),
          'useSearchPublicUrls',
        )
      : process.env.GATSBY_WEBSITE === 'keyrus-management'
      ? useStaticQueryImport(
          import('../../../../keyrus-management/src/hooks/useSearchPublicUrls'),
          'useSearchPublicUrls',
        )
      : process.env.GATSBY_WEBSITE === 'keyrus-nearshoring'
      ? useStaticQueryImport(
          import(
            '../../../../keyrus-nearshoring/src/hooks/useSearchPublicUrls'
          ),
          'useSearchPublicUrls',
        )
      : process.env.GATSBY_WEBSITE === 'keyrus-china'
      ? useStaticQueryImport(
          import('../../../../keyrus-china/src/hooks/useSearchPublicUrls'),
          'useSearchPublicUrls',
        )
      : useStaticQueryImport(
          import('../../../../keyrus/src/hooks/useSearchPublicUrls'),
          'useSearchPublicUrls',
        )

  const handleClick = () => setFocus(prevState => !prevState)

  return (
    <div>
      <SearchMenuItem ref={SearchMenuItemRef} onClick={handleClick} />
      {data && (
        <LocalSearch
          country={country}
          navLocale={navLocale}
          show={hasFocus}
          setFocus={setFocus}
          isMultiLang={isMultiLang}
          searchPlaceholder={searchPlaceholder}
          data={data}
          countrySubServices={countrySubServices}
          toggleButtonRef={SearchMenuItemRef}
          pageData={pageData}
        />
      )}
    </div>
  )
}

Search.propTypes = {
  country: string,
  isMultiLang: string,
  navLocale: string,
  searchPlaceholder: string,
}

Search.defaultProps = {
  country: '',
  isMultiLang: '',
  navLocale: '',
  searchPlaceholder: '',
}

export default Search
