import * as R from 'ramda'
import {arrayOf, objectOf, string} from 'prop-types'
import {BgImage} from 'gbimage-bridge'
import {Button} from '@mui/material'
import {Field, Form, Formik} from 'formik'
import {Link} from 'gatsby'
import {useFlexSearch} from 'react-use-flexsearch'
import classNames from 'classnames'
import React, {useEffect, useRef, useState} from 'react'
import SearchIcon from '@mui/icons-material/Search'
import Slide from '@mui/material/Slide'

import {slugTransformer} from 'helpers/utils'
import CloseButton from 'components/UI/CloseButton'
import Media from 'components/UI/Media'

import useStyles from './styles'

const FlexSearch = ({
  country,
  navLocale,
  insightsIndex,
  insightsStore,
  keyplaysIndex,
  keyplaysStore,
  servicesIndex,
  servicesStore,
  searchPlaceholder,
  countrySubServices,
  handleToggleSearch,
  pageData,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const searchInput = useRef(null)

  function getSavedValue() {
    const savedValue = sessionStorage.getItem('searchQuery')

    return savedValue || ''
  }

  const [query, setQuery] = useState(() => getSavedValue())

  useEffect(() => sessionStorage.setItem('searchQuery', query), [query])

  useEffect(() => {
    searchInput.current.focus()
  }, [])

  const options = {
    limit: 20,
    offset: 100,
    suggest: true,
  }

  const insightsResults = useFlexSearch(
    query,
    insightsIndex,
    insightsStore,
    options,
  )
  const keyplaysResults = useFlexSearch(
    query,
    keyplaysIndex,
    keyplaysStore,
    options,
  )
  const servicesResults = useFlexSearch(
    query,
    servicesIndex,
    servicesStore,
    options,
  )

  const createSlug = (prefix, slug) => {
    if (country === 'worldwide') {
      return `/${country}/${prefix}/${slug}`
    }

    return `/${country}/${navLocale}/${prefix}/${slug}`
  }

  const InsightsResults = () =>
    mapIndexed((result, index) => {
      const {image, slug, title, type, metaDescription, publicationDate} =
        result

      return (
        <Link key={index} to={createSlug('insights', slug)}>
          <div className={classes.listItem}>
            <BgImage
              Tag="picture"
              image={image.gatsbyImageData}
              className={classes.image}
            >
              <div className={classes.image} />
            </BgImage>
            <div>
              <h3 className={classes.type}>{type.name}</h3>
              <li>{title}</li>
              <p className={classes.date}>{publicationDate}</p>
              <p className={classes.metaDescription}>{metaDescription}</p>
            </div>
          </div>
        </Link>
      )
    }, insightsResults)

  const KeyplaysResults = () =>
    mapIndexed((result, index) => {
      const {
        image,
        slug,
        title,
        metaDescription,
        departments,
        industries,
        partners,
      } = result
      const RenderDepartments = () =>
        departments &&
        R.map(
          department => (
            <Button
              className={classNames(classes.departmentTags, classes.tag)}
              size="small"
            >
              #{R.pathOr(' ', ['name'], department)}
            </Button>
          ),
          R.slice(0, 3, departments),
        )
      const RenderIndutries = () =>
        industries &&
        R.map(
          industry => (
            <Button
              className={classNames(classes.industrieTags, classes.tag)}
              size="small"
            >
              #{R.pathOr(' ', ['name'], industry)}
            </Button>
          ),
          R.slice(0, 3, industries),
        )
      const RenderPartners = () =>
        partners &&
        R.map(
          partner => (
            <Button
              className={classNames(classes.partnerTags, classes.tag)}
              size="small"
            >
              #{partner}
            </Button>
          ),
          R.slice(0, 3, partners),
        )

      return (
        <Link key={index} to={createSlug('playbook', slug)}>
          <div className={classes.listItem}>
            <BgImage
              Tag="picture"
              image={image.gatsbyImageData}
              className={classes.image}
            >
              <div className={classes.image} />
            </BgImage>
            <div>
              <div className={classes.tagsContainer}>
                <RenderDepartments />
                <RenderIndutries />
                <RenderPartners />
              </div>
              <li>{title}</li>
              <p className={classes.metaDescription}>{metaDescription}</p>
            </div>
          </div>
        </Link>
      )
    }, keyplaysResults)

  const newServicesResults = R.filter(
    item => R.contains(item.objectID, countrySubServices),
    servicesResults,
  )

  const ServicesResults = () =>
    mapIndexed((result, index) => {
      const {slug, name, imageOrIcon, metaDescription} = result

      return (
        <Link key={index} to={slugTransformer(createSlug('services', slug))}>
          <div className={classes.listItem}>
            <div className={classes.svgImage}>
              <Media className={classes.svgImage} data={imageOrIcon} />
            </div>
            <div>
              <li>{name}</li>
              <p className={classes.metaDescription}>{metaDescription}</p>
            </div>
          </div>
        </Link>
      )
    }, newServicesResults)

  const hasInsightsResults = R.length(insightsResults) > 0
  const hasKeyplayResults = R.length(keyplaysResults) > 0
  const hasServicesResults = R.length(newServicesResults) > 0
  const hasResults =
    hasKeyplayResults || hasInsightsResults || hasServicesResults
  const hasQuery = !!query
  const searchQueryIsEmpty = R.length(query) === 0
  const [notFound, setNotfound] = useState(false)

  useEffect(() => {
    if (hasResults) {
      setNotfound(false)
    } else if (searchQueryIsEmpty) {
      setNotfound(false)
    } else if (!hasResults && hasQuery) {
      setNotfound(true)
    }
  }, [hasResults, hasQuery])

  return (
    <Slide direction="bottom" in>
      <div className={classes.container}>
        <Formik
          initialValues={{query}}
          onSubmit={(values, {setSubmitting}) => {
            setQuery(values.query)
            setSubmitting(false)
          }}
        >
          <Form className={classes.form}>
            <SearchIcon className={classes.searchIcon} />
            <Field
              as="input"
              placeholder={searchPlaceholder}
              innerRef={searchInput}
              className={classes.searchInput}
              name="query"
            />
            <CloseButton hasNoClass action={handleToggleSearch} />
          </Form>
        </Formik>
        {hasResults && (
          <div className={classes.results}>
            {hasServicesResults && (
              <>
                <div className={classes.titleResults}>
                  <h3>Services</h3>
                  <span>{newServicesResults.length} Results</span>
                </div>
                <ul className={classes.list}>
                  <ServicesResults />
                </ul>
              </>
            )}
            {hasKeyplayResults && (
              <>
                <div className={classes.titleResults}>
                  <h3>Keyplays</h3>
                  <span>{keyplaysResults.length} Results</span>
                </div>
                <ul className={classes.list}>
                  <KeyplaysResults />
                </ul>
              </>
            )}
            <br />
            {hasInsightsResults && (
              <>
                <div className={classes.titleResults}>
                  <h3>Insights</h3>
                  <span>{insightsResults.length} Results</span>
                </div>
                <ul className={classes.list}>
                  <InsightsResults />
                </ul>
              </>
            )}
          </div>
        )}
        {notFound && <div className={classes.noResults}>No results found</div>}
      </div>
    </Slide>
  )
}

FlexSearch.propTypes = {
  country: string,
  insightsIndex: arrayOf(string),
  insightsStore: objectOf(string),
  keyplaysIndex: arrayOf(string),
  keyplaysStore: objectOf(string),
  navLocale: string,
  searchPlaceholder: string,
}

FlexSearch.defaultProps = {
  country: '',
  insightsIndex: [],
  insightsStore: {},
  keyplaysIndex: [],
  keyplaysStore: {},
  navLocale: '',
  searchPlaceholder: '',
}

export default FlexSearch
