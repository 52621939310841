import * as R from 'ramda'
import {arrayOf, bool, number, objectOf, shape, string} from 'prop-types'
import {reorderedContinentsPropTypes} from 'helpers/propTypes'
import loadable from '@loadable/component'
import React from 'react'

import classNames from 'classnames'
import ContactUsComponent from 'components/UI/ContactUs'
import DropDownLink from 'components/Layout/DropDownLink'
import Search from 'components/UI/Search'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const NavList = ({
  navData,
  pageData,
  menu,
  countryData,
  pageContext,
  toggleSwitchLang,
  isModalLangOpen,
  setIsModalLangOpen,
  popperData,
  setPopperData,
  reorderedContinents,
  jobRecordCount,
  toggleButtonRef,
  ourSitesLabel,
  countrySubServices,
}) => {
  const classes = useStyles()
  const SwitchLanguage = loadable(() =>
    import('components/Layout/SwitchLanguage'),
  )

  const {
    allSitePage,
    searchPlaceholder,
    contactUs,
    contactUsLink,
    findAJob,
    currentCountryFindAJobLink,
    renderTranslatedCountry,
  } = navData

  const isMobile = useIsMobile()

  const {countryCode, technicalName, navLocale, isMultilang} = countryData

  const {prefix, hasShortPath} = pageContext

  return (
    <>
      {R.map(
        ({
          id,
          title,
          link,
          childMenu,
          isItAnExternalLink,
          shouldThisLinkOpenInANewBrowserWindow,
          isSameMenuItemForAllCountries,
          menuItemPerCountry,
        }) => (
          <DropDownLink
            menuItemPerCountry={menuItemPerCountry}
            country={technicalName}
            menuTitle={title}
            menuLink={link}
            childMenu={childMenu}
            key={`${id}`}
            isItAnExternalLink={isItAnExternalLink}
            shouldThisLinkOpenInANewBrowserWindow={
              shouldThisLinkOpenInANewBrowserWindow
            }
            isSameMenuItemForAllCountries={isSameMenuItemForAllCountries}
            prefix={prefix}
            hasShortPath={hasShortPath}
            countryCode={countryCode}
            navLocale={navLocale}
            jobRecordCount={jobRecordCount}
          />
        ),
        menu,
      )}
      {!isMobile && (
        <Search
          country={countryCode}
          navLocale={navLocale}
          searchPlaceholder={searchPlaceholder}
          isMultiLang={isMultilang}
          countrySubServices={countrySubServices}
          pageData={pageData}
        />
      )}
      <div className={classes.navigationItem}>
        <ContactUsComponent
          contactUsTitle={contactUs.title}
          contactUsLink={contactUsLink}
          findAJobTitle={findAJob.title}
          findAJobLink={currentCountryFindAJobLink}
        />
      </div>
      <div
        className={classNames(classes.bridgeHover, {
          [classes.bridgeHoverShape]: isModalLangOpen,
        })}
      >
        <button
          ref={toggleButtonRef}
          onClick={() => toggleSwitchLang()}
          type="button"
          className={classes.switchLanguageButton}
        >
          {renderTranslatedCountry}
        </button>
        {isModalLangOpen && (
          <SwitchLanguage
            isModalLangOpen={isModalLangOpen}
            setIsModalLangOpen={setIsModalLangOpen}
            continents={reorderedContinents}
            currentCountry={technicalName}
            currentNodeLocale={navLocale}
            popperData={popperData}
            setPopperData={setPopperData}
            allSitePage={allSitePage}
            toggleButtonRef={toggleButtonRef}
            ourSitesLabel={ourSitesLabel}
          />
        )}
      </div>
    </>
  )
}

export default NavList

NavList.propTypes = {
  countryData: shape().isRequired,
  isModalLangOpen: bool.isRequired,
  jobRecordCount: number.isRequired,
  menu: shape().isRequired,
  navData: shape({
    allCountries: arrayOf(shape()).isRequired,
    allSitePage: arrayOf(string).isRequired,
    contactUs: shape({
      link: string.isRequired,
      title: string.isRequired,
    }).isRequired,
    contactUsLink: string.isRequired,
    currentCountryFindAJobLink: string.isRequired,
    findAJob: shape({
      title: string.isRequired,
      menuItemPerCountry: arrayOf().isRequired,
    }).isRequired,
    headerData: arrayOf(shape()).isRequired,
    headerMenu: shape({
      menuItems: arrayOf().isRequired,
    }).isRequired,
    localizedHeader: arrayOf().isRequired,
    localizedSearchPlaceholder: arrayOf().isRequired,
    logo: shape({
      title: string.isRequired,
      file: shape({
        url: string.isRequired,
      }).isRequired,
    }).isRequired,
    playBookData: arrayOf(shape()).isRequired,
    renderTranslatedCountry: string.isRequired,
    reorderedContinents: reorderedContinentsPropTypes.isRequired,
    routing: string.isRequired,
    searchPlaceholder: string.isRequired,
  }).isRequired,
  pageContext: shape().isRequired,
  popperData: shape().isRequired,
  reorderedContinents: reorderedContinentsPropTypes.isRequired,
  setIsModalLangOpen: shape().isRequired,
  setPopperData: shape().isRequired,
  toggleButtonRef: objectOf().isRequired,
  toggleSwitchLang: shape().isRequired,
}
