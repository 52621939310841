import * as R from 'ramda'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import loadable from '@loadable/component'
import React, {useEffect, useState} from 'react'

import {getServices} from 'helpers/services'
import defaultTheme from 'config/theme'
import Footer from 'components/Layout/FooterKeyrus'
import Header from 'components/Layout/HeaderKeyrus'
import palette1 from 'config/palette1'
import useAosInit from 'hooks/useAosInit'
import useIsMobile from 'hooks/useIsMobile'
import useIsPartnersPage from 'hooks/useIsPartnersPage'
import useMountComponent from 'hooks/useMountComponent'

import useStyles from './styles'

const LayoutKeyrus = React.memo(props => {
  const Popper = loadable(() => import('components/UI/Popper'))
  const ScrollTop = loadable(() => import('components/UI/ScrollTopButton'))
  const isPartnersPage = useIsPartnersPage()
  const {
    children,
    data,
    pageHasNoNavigation,
    layoutData,
    pageContext,
    jobRecordCount,
  } = props
  const isMobile = useIsMobile()
  const countryData = R.path(['contentfulCountry'], data)

  const services = R.pathOr(null, ['contentfulCountry', 'services'], data)

  const topLevelServices =
    services && R.pathOr(null, ['topLevelServices'], services)

  const countryServices =
    topLevelServices && getServices(data, topLevelServices)

  const classes = useStyles()

  const [showLayout, setShowLayout] = useState(false)

  const isLayoutMounted = useMountComponent()
  const showNav =
    process.env.GATSBY_IN_SITE_MAINTENANCE === 'false' && !pageHasNoNavigation

  const localizedPopper =
    showNav &&
    layoutData.allContentfulRedirectionPopUp.edges.find(({node}) => {
      if (!node.countries) {
        return false
      }

      return node.countries.find(
        country =>
          country.technicalName === countryData.technicalName &&
          node.node_locale === pageContext.nodeLocale,
      )
    })

  const defaultPopperContent =
    showNav &&
    layoutData.allContentfulRedirectionPopUp.edges.find(
      ({node}) =>
        node.countries === null && node.node_locale === pageContext.nodeLocale,
    )

  const popperContent =
    showNav && localizedPopper
      ? localizedPopper.node
      : defaultPopperContent.node

  const [popperData, setPopperData] = useState(
    showNav && {
      link: '',
      text: popperContent.textContent.textContent,
      cta1: popperContent.cta1,
      cta2: popperContent.cta2,
      showPopper: false,
    },
  )

  const handleLayoutDisplay = bool =>
    bool
      ? {visibility: 'visible', marginTop: isMobile ? 25 : 100}
      : {visibility: 'hidden'}

  useEffect(() => {
    setShowLayout(isLayoutMounted)
  }, [isLayoutMounted])

  useAosInit(showLayout)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <ThemeProvider theme={palette1}>
          <Popper
            showPopper={popperData.showPopper}
            setShowPopper={() =>
              setPopperData({...popperData, showPopper: false})
            }
            link={popperData.link}
            label={popperData.text}
            cta1={popperData.cta1}
            cta2={popperData.cta2}
            showLink={false}
            shouldOpenInNewTab={false}
          />
          <div
            style={handleLayoutDisplay(showLayout)}
            className={showNav && classes.wrapper}
          >
            {showNav && (
              <Header
                pageContext={pageContext}
                countryData={countryData}
                layoutData={layoutData}
                pageData={data}
                popperData={popperData}
                setPopperData={setPopperData}
                countryServices={countryServices}
              />
            )}
            {children}
            {showNav && (
              <Footer
                pageContext={pageContext}
                countryData={countryData}
                data={layoutData}
              />
            )}
            {!isPartnersPage && <ScrollTop />}
          </div>
        </ThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
})

export default LayoutKeyrus
