import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, palette, spacing}) => ({
  navigationItem: {
    [breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: spacing(4),
      marginRight: spacing(0),
    },
  },

  wrapper: {
    borderRadius: 35,
    border: `1px solid ${palette.primary.main}`,
    background: 'white',
    color: palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'CamptonBook',
    fontSize: '1em',
    height: 55,
    lineHeight: spacing(3),
    padding: `0 ${spacing(1.15)}`,
    position: 'relative',
    transition:
      'height .3s, margin .3s, color .3s, background-color .3s, display .3s, box-shadow .3s, width .3s',
    width: 'fit-content',
    zIndex: 1,

    '&:hover': {
      height: 110,
      width: 'fit-content',
      marginTop: 55,
      background: palette.primary.main,
      color: 'white',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      '& a:nth-child(2)': {
        visibility: 'visible',
        opacity: 1,
        [breakpoints.down('md')]: {
          display: 'block',
        },
      },
      [breakpoints.down('md')]: {
        height: 'auto',
      },
    },

    '& a': {
      alignItems: 'center',
      borderRadius: 35,
      color: 'inherit',
      display: 'flex',
      height: 55,
      justifyContent: 'center',
      textAlign: 'center',
      '&:hover': {
        textDecoration: 'underline !important',
      },
    },

    '& a:nth-child(1)': {
      padding: `${spacing(1.8)} ${spacing(1.15)}`,
      [breakpoints.down('md')]: {
        height: 'fit-content',
      },
    },

    '& a:nth-child(2)': {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s, opacity 0.3s linear',
      [breakpoints.down('md')]: {
        display: 'none',
      },
    },

    [breakpoints.down('md')]: {
      height: 'fit-content',
    },
  },

  mobileWrapper: {
    textAlign: 'right',
  },
}))

export default useStyles
