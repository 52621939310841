import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, palette, spacing, zIndex}) => ({
  navigationItem: {
    [breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: spacing(4),
      marginRight: spacing(0),
    },
  },
  bridgeHover: {
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
    },
  },
  bridgeHoverShape: {
    '& > button::before': {
      cursor: 'default',
      top: 53,
      width: '122%',
      height: 23,
      content: "''",
      zIndex: zIndex.header,
      position: 'absolute',
    },
  },
  switchLanguageButton: {
    '&:hover': {
      background: palette.background.greyLight,
    },
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
    border: 'none',
    backgroundColor: 'transparent',
    fontFamily: 'CamptonBook',
  },
}))

export default useStyles
