import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, layout, spacing}) => ({
  logoKeyrus: {
    marginTop: 6,
    paddingRight: spacing(1.8),
    '& > img': {
      width: layout.logo.width,
      height: layout.logo.height,
      [breakpoints.down('sm')]: {
        width: layout.logo.mobile.width,
        height: layout.logo.mobile.height,
      },
    },
  },
  logoKeyrusModal: {
    marginTop: spacing(4),
    marginBottom: spacing(1.5),
  },
}))

export default useStyles
