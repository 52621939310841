import * as R from 'ramda'
import {dynamicRouting} from 'helpers/utils'

export default function renderNavData(data, pageContext, countryData) {
  const playBookData = R.pathOr(null, ['allContentfulPlaybook', 'edges'], data)
  const headerData = R.pathOr(null, ['allContentfulHeader', 'edges'], data)
  const allSitePage = R.pathOr(null, ['allSitePage', 'distinct'], data)

  const {countryCode, technicalName, urlPrefix, navLocale} = countryData

  const {hasShortPath, nodeLocale, prefix} = pageContext

  const localizedSearchPlaceholder = R.filter(
    el => el.node.node_locale === nodeLocale,
    playBookData,
  )

  const localizedHeader = R.filter(
    el => el.node.node_locale === nodeLocale,
    headerData,
  )

  const {logo, headerMenu} = R.pathOr(null, [0, 'node'], localizedHeader)
  const {searchPlaceholder} = R.pathOr(
    null,
    [0, 'node'],
    localizedSearchPlaceholder,
  )

  const localizedContinents = R.filter(
    el => el.node.node_locale === nodeLocale,
    data.allContentfulContinent.edges,
  )

  const allCountries = [
    ...localizedContinents[0].node.countries,
    ...localizedContinents[1].node.countries,
    ...localizedContinents[2].node.countries,
    ...localizedContinents[3].node.countries,
    ...localizedContinents[4].node.countries,
  ]

  const translatedCountry =
    urlPrefix && allCountries.filter(o => o.urlPrefix === urlPrefix)

  const contactUs = R.pathOr(null, [0, 'node', 'contactUs'], localizedHeader)
  const findAJob = R.pathOr(null, [0, 'node', 'findAJob'], localizedHeader)
  const ourSitesLabel = R.pathOr(
    null,
    [0, 'node', 'ourSitesLabel'],
    localizedHeader,
  )

  const contactUsLink = R.equals(countryCode, 'worldwide')
    ? `/${countryCode}/${contactUs.link}`
    : `/${countryCode}/${navLocale}/${contactUs.link}`

  const [currentCountryFindAJobLinkMenuItem] = R.filter(
    e => e.countries[0].technicalName === technicalName,
    findAJob.menuItemPerCountry,
  )

  const currentCountryFindAJobLink = R.pathOr(
    '',
    ['link'],
    currentCountryFindAJobLinkMenuItem,
  )

  const renderTranslatedCountry =
    (translatedCountry &&
      translatedCountry.length > 0 &&
      (R.equals(translatedCountry[0].countryCode, 'worldwide')
        ? translatedCountry[0].name
        : translatedCountry[0].countryCode.toUpperCase())) ||
    ''

  const routing = dynamicRouting(
    prefix,
    'home',
    countryCode,
    hasShortPath,
    navLocale,
  )

  const reorderedContinents = [
    {...localizedContinents[3]},
    {...localizedContinents[0]},
    {...localizedContinents[1]},
    {...localizedContinents[2]},
    {...localizedContinents[4]},
  ]

  return {
    playBookData,
    headerData,
    allSitePage,
    localizedSearchPlaceholder,
    localizedHeader,
    logo,
    headerMenu,
    searchPlaceholder,
    contactUs,
    contactUsLink,
    currentCountryFindAJobLink,
    renderTranslatedCountry,
    allCountries,
    findAJob,
    routing,
    reorderedContinents,
    ourSitesLabel,
  }
}
