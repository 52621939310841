import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, layout, palette, spacing}) => ({
  hamburger: {
    all: 'inherit',
    display: 'grid',
    width: layout.header.hamburger.width,
    height: layout.header.hamburger.height,
    marginRight: 0,
    cursor: 'pointer',
    margin: 'auto',
    '&::before': {
      width: '1em',
      height: 2,
      content: '""',
      position: 'absolute',
      background: 'black',
      boxShadow: '0 0.5em 0 0 black, 0 1em 0 0 black',
    },
    '&:hover': {
      '&::before': {
        background: palette.primary.main,
        boxShadow: `0 0.5em 0 0 ${palette.primary.main}, 0 1em 0 0 ${palette.primary.main}`,
      },
    },
  },

  bgModal: {
    top: '0',
    right: '0',
    position: 'absolute',
    background: '#0d1034',
    opacity: 0.4,
    width: '100vw',
    height: '100vh',
    touchAction: 'none',
  },
  modal: {
    top: '0',
    right: '0',
    maxWidth: 320,
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2.8, 0, 2.8, 2.8),
    position: 'absolute',
    background: 'white',
    alignIitems: 'flex-end',
    height: '100vh',
    [breakpoints.down('sm')]: {
      zIndex: 1,
    },
  },
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    height: layout.header.navigation.height,
    maxHeight: 100,
    fontSize: '1.13em',
    '& > button:last-of-type, & > a > button:last-of-type': {
      marginLeft: spacing(1.7),
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      maxHeight: 'inherit',
      alignItems: 'flex-end',
      '& > button:last-of-type': {
        marginLeft: 0,
      },
    },
  },

  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%',
  },

  renderDeskopNav: {
    [breakpoints.down('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
    [breakpoints.up('md')]: {
      display: 'block',
      visibility: 'visible',
    },
  },
  renderMobileNav: {
    width: '90% !important',
    [breakpoints.up('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },

  scrollable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '3vw',
    width: '100%',
    '& > *': {
      paddingRight: spacing(1.8),
    },
    '& > li:first-of-type': {
      marginTop: spacing(2),
    },
    '& > div:last-of-type': {
      marginBottom: spacing(5),
    },
    '& > button:last-of-type': {
      marginBottom: spacing(5),
    },
    [breakpoints.down('sm')]: {
      height: 'calc(100vh - 113px)',
      '& > button:last-of-type': {
        maxWidth: layout.header.scrollable.maxWidth,
        width: layout.header.scrollable.width,
        marginRight: spacing(1.1),
      },
      overflowY: 'scroll',
      overflowX: 'hide',
    },
  },
}))

export default useStyles
