import {bool, func, node, shape, string} from 'prop-types'
import React from 'react'

import {
  pageContextPropTypes,
  subLevelServicesPropTypes,
} from 'helpers/propTypes'
import useJobOffers from 'hooks/useJobOffers'

import Nav from './Nav'
import useStyles from './styles'

const Header = ({
  pageContext,
  countryData,
  layoutData,
  pageData,
  popperData,
  setPopperData,
  countryServices,
}) => {
  const {teamTailorApiKey} = countryData
  const classes = useStyles()
  const {jobRecordCount} = useJobOffers(teamTailorApiKey)

  return (
    <header className={classes.container}>
      <Nav
        data={layoutData}
        pageData={pageData}
        countryData={countryData}
        pageContext={pageContext}
        popperData={popperData}
        setPopperData={setPopperData}
        countryServices={countryServices}
        jobRecordCount={jobRecordCount}
      />
    </header>
  )
}

Header.propTypes = {
  countryData: node,
  countryServices: subLevelServicesPropTypes,
  data: node,
  pageContext: pageContextPropTypes,
  popperData: shape({
    link: string,
    text: string,
    cta1: string,
    cta2: string,
    showPopper: bool,
  }),
  setPopperData: func,
}

Header.defaultProps = shape({
  configSlug: null,
  contentId: '',
  countryServices: null,
  menuItemsQuery: null,
  popperData: null,
  setPopperData: null,
})

export default Header
