import * as R from 'ramda'
import {bool, func, node, shape, string} from 'prop-types'
import {
  pageContextPropTypes,
  subLevelServicesPropTypes,
} from 'helpers/propTypes'
import Fade from '@mui/material/Fade'
import loadable from '@loadable/component'
import React, {createRef, useEffect, useState} from 'react'

import {
  renderCustomServicesMenu,
  replaceServiceMenu,
} from 'helpers/customServicesMenu'
import CloseButton from 'components/UI/CloseButton'
import renderNavData from 'helpers/renderNavData'
import Search from 'components/UI/Search'
import useIsMobile from 'hooks/useIsMobile'

import LogoCorp from '../LogoCorp'
import NavList from '../NavList'
import useStyles from './styles'

const Nav = ({
  data,
  pageData,
  countryData,
  pageContext,
  popperData,
  setPopperData,
  countryServices,
  jobRecordCount,
}) => {
  const SwitchLanguage = loadable(() =>
    import('components/Layout/SwitchLanguage'),
  )

  const toggleButtonRef = createRef()

  const classes = useStyles()
  const isMobile = useIsMobile()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalLangOpen, setIsModalLangOpen] = useState(false)

  const {countryCode, navLocale, isMultilang, technicalName} = countryData

  const {
    allSitePage,
    logo,
    headerMenu,
    searchPlaceholder,
    reorderedContinents,
    routing,
    ourSitesLabel,
  } = renderNavData(data, pageContext, countryData)

  const navData = renderNavData(data, pageContext, countryData)

  useEffect(() => {
    document.body.style.overflowY =
      isModalOpen || isModalLangOpen ? 'scroll' : 'scroll'
  }, [isModalOpen, isModalLangOpen])

  const openMenuMobile = () => {
    setIsModalOpen(true)
    setIsModalLangOpen(false)
  }

  const toggleSwitchLang = () => {
    setIsModalOpen(false)
    setIsModalLangOpen(!isModalLangOpen)
  }

  const isServicesItem = R.either(
    R.propEq('link', 'services'),
    R.propSatisfies(link => link.startsWith('services/'), 'link'),
  )

  const servicesItems =
    R.filter(isServicesItem, headerMenu.menuItems)[0].childMenu.menuItems &&
    R.filter(isServicesItem, headerMenu.menuItems)[0].childMenu.menuItems[0]

  const customMenu = renderCustomServicesMenu(
    countryServices,
    technicalName === 'France' || technicalName === 'Belgium'
      ? servicesItems
      : {},
  )[0].childMenu

  const updatedMenu = replaceServiceMenu(customMenu, headerMenu.menuItems)

  const concatSubLevelServicesIds = R.pipe(
    R.pluck('subLevelServicesIds'),
    R.flatten,
    R.pluck('entryId'),
  )

  const countrySubServices = concatSubLevelServicesIds(countryServices)

  const renderNavList = () => (
    <NavList
      navData={navData}
      pageData={pageData}
      menu={updatedMenu}
      countryData={countryData}
      pageContext={pageContext}
      // eslint-disable-next-line react/jsx-no-bind
      toggleSwitchLang={toggleSwitchLang}
      isModalLangOpen={isModalLangOpen}
      setIsModalLangOpen={setIsModalLangOpen}
      popperData={popperData}
      setPopperData={setPopperData}
      reorderedContinents={reorderedContinents}
      jobRecordCount={jobRecordCount}
      toggleButtonRef={toggleButtonRef}
      ourSitesLabel={ourSitesLabel}
      countrySubServices={countrySubServices}
    />
  )

  const MobileNavModule = () => (
    <>
      <div style={{display: 'flex'}}>
        <Search
          country={countryCode}
          navLocale={navLocale}
          isMultiLang={isMultilang}
          searchPlaceholder={searchPlaceholder}
          countrySubServices={countrySubServices}
          pageData={pageData}
        />
        <button
          aria-label="open menu"
          label="open menu"
          type="button"
          onClick={() => openMenuMobile()}
          className={classes.hamburger}
        />
      </div>
      {isModalOpen && isMobile && (
        <>
          <div className={classes.bgModal} />
          <Fade appear={false} in={isModalOpen}>
            <aside className={classes.modal}>
              <ul className={classes.navigation}>
                {isMobile && (
                  <>
                    <CloseButton action={setIsModalOpen} />
                    <LogoCorp
                      routing={routing}
                      isModalOpen={isModalOpen}
                      logo={logo}
                      modal
                    />
                    <div className={classes.scrollable}>{renderNavList()}</div>
                  </>
                )}
              </ul>
            </aside>
          </Fade>
        </>
      )}
      {isModalLangOpen && (
        <SwitchLanguage
          isModalLangOpen={isModalLangOpen}
          setIsModalLangOpen={setIsModalLangOpen}
          continents={reorderedContinents}
          currentCountry={technicalName}
          currentNodeLocale={navLocale}
          popperData={popperData}
          setPopperData={setPopperData}
          allSitePage={allSitePage}
          ourSitesLabel={ourSitesLabel}
        />
      )}
    </>
  )

  return (
    <div
      className={isMobile ? classes.renderMobileNav : classes.renderDeskopNav}
    >
      <div>
        <nav role="navigation" className={classes.menu}>
          <LogoCorp routing={routing} isModalOpen={isModalOpen} logo={logo} />

          {isMobile ? (
            <MobileNavModule />
          ) : (
            <ul className={classes.navigation}>{renderNavList()}</ul>
          )}
        </nav>
      </div>
    </div>
  )
}

export default Nav

Nav.propTypes = {
  countryData: node,
  countryServices: subLevelServicesPropTypes,
  data: node,
  pageContext: pageContextPropTypes,
  popperData: shape({
    link: string,
    text: string,
    cta1: string,
    cta2: string,
    showPopper: bool,
  }),
  setPopperData: func,
}

Nav.defaultProps = shape({
  countryData: null,
  countryServices: null,
  data: null,
  pageContext: {},
  popperData: {
    link: '',
    text: '',
    cta1: '',
    cta2: '',
    showPopper: false,
  },
  setPopperData: null,
})
