import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, breakpoints, fontSizes, spacing}) => ({
  description: {
    fontFamily: 'CamptonLight',
    color: palette.text.primary,
    marginBottom: spacing(1.25),
    fontSize: fontSizes.description,
  },
  classicDescription: {
    fontSize: fontSizes.description,
  },
  largeDescription: {
    fontSize: fontSizes.largeDescription,
    [breakpoints.down('sm')]: {
      fontSize: fontSizes.description,
    },
  },
  justify: {
    textAlign: 'justify',
  },
  centered: {
    textAlign: 'center',
  },
  marginTop: {
    marginTop: `${spacing(3.25)}!important`,
    [breakpoints.down('sm')]: {
      marginTop: `${spacing(2.5)}!important`,
    },
  },
  marginBottom: {
    marginBottom: `${spacing(5)}!important`,
    [breakpoints.down('sm')]: {
      marginBottom: `${spacing(2.5)}!important`,
    },
  },
  colorWhite: {
    color: 'white',
  },
  preLine: {
    whiteSpace: 'pre-line',
  },
  italic: {
    fontStyle: 'italic',
  },
}))

export default useStyles
