import * as R from 'ramda'
import {bool, func, objectOf, string} from 'prop-types'
import {globalHistory} from '@reach/router'
import {upperFirst} from 'lodash'
import React, {createRef, useEffect} from 'react'

import useClickOutside from 'hooks/useClickOutside'
import useFetch from 'hooks/useFetch'
import useFetchObject from 'hooks/useFetchObject'

import FlexSearch from './FlexSearch/index'

const LocalSearch = ({
  country,
  data,
  navLocale,
  show,
  setFocus,
  isMultiLang,
  searchPlaceholder,
  countrySubServices,
  toggleButtonRef,
  pageData,
}) => {
  const rootRef = createRef()

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        setFocus(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [show])

  useEffect(() => {
    const handleRouteChange = () => {
      setFocus(false)
    }

    const unsubscribe = globalHistory.listen(({action}) => {
      if (action === 'PUSH') {
        handleRouteChange()
      }
    })

    return () => {
      unsubscribe()
    }
  }, [setFocus])

  const handleToggleSearch = () => setFocus(false)

  useClickOutside(rootRef, toggleButtonRef, handleToggleSearch)

  const defaultInsightsIndex = data?.localSearchInsightsEn?.publicIndexURL
  const defaultInsightsStore = data?.localSearchInsightsEn?.publicStoreURL
  const defaultKeyplaysIndex = data?.localSearchKeyplaysEn?.publicIndexURL
  const defaultKeyplaysStore = data?.localSearchKeyplaysEn?.publicStoreURL
  const defaultSercicesIndex = data?.localSearchServicesEn?.publicStoreURL
  const defaultServicesStore = data?.localSearchServicesEn?.publicStoreURL

  const getSearchInsightsCountryKey = () => {
    if (isMultiLang) {
      return `localSearchInsights${upperFirst(country)}${upperFirst(navLocale)}`
    }

    return `localSearchInsights${upperFirst(country)}`
  }

  const getSearchKeyplaysCountryKey = () => {
    if (isMultiLang) {
      return `localSearchKeyplays${upperFirst(country)}${upperFirst(navLocale)}`
    }

    return `localSearchKeyplays${upperFirst(country)}`
  }
  const getSearchServicesCountryKey = () => {
    if (isMultiLang) {
      return `localSearchServices${upperFirst(country)}${upperFirst(navLocale)}`
    }

    return `localSearchServices${upperFirst(country)}`
  }

  const localSearchInsightsCountryKey = getSearchInsightsCountryKey()
  const localSearchKeyplaysCountryKey = getSearchKeyplaysCountryKey()
  const localSearchServicesCountryKey = getSearchServicesCountryKey()

  const insightsIndexUrl = R.pathOr(
    defaultInsightsIndex,
    [localSearchInsightsCountryKey, 'publicIndexURL'],
    data,
  )
  const insightsStoreUrl = R.pathOr(
    defaultInsightsStore,
    [localSearchInsightsCountryKey, 'publicStoreURL'],
    data,
  )
  const keyplaysIndexUrl = R.pathOr(
    defaultKeyplaysIndex,
    [localSearchKeyplaysCountryKey, 'publicIndexURL'],
    data,
  )
  const keyplaysStoreUrl = R.pathOr(
    defaultKeyplaysStore,
    [localSearchKeyplaysCountryKey, 'publicStoreURL'],
    data,
  )
  const servicesIndexUrl = R.pathOr(
    defaultSercicesIndex,
    [localSearchServicesCountryKey, 'publicIndexURL'],
    data,
  )
  const servicesStoreUrl = R.pathOr(
    defaultServicesStore,
    [localSearchServicesCountryKey, 'publicStoreURL'],
    data,
  )

  const [insightsIndex] = useFetch(insightsIndexUrl)
  const [insightsStore] = useFetchObject(insightsStoreUrl)
  const [keyplaysIndex] = useFetch(keyplaysIndexUrl)
  const [keyplaysStore] = useFetchObject(keyplaysStoreUrl)
  const [servicesIndex] = useFetch(servicesIndexUrl)
  const [servicesStore] = useFetchObject(servicesStoreUrl)

  return (
    <div ref={rootRef}>
      {insightsIndex &&
        insightsStore &&
        keyplaysIndex &&
        keyplaysStore &&
        servicesIndex &&
        servicesStore &&
        show && (
          <FlexSearch
            country={country}
            navLocale={navLocale}
            insightsIndex={insightsIndex}
            insightsStore={insightsStore}
            keyplaysIndex={keyplaysIndex}
            keyplaysStore={keyplaysStore}
            servicesIndex={servicesIndex}
            servicesStore={servicesStore}
            searchPlaceholder={searchPlaceholder}
            countrySubServices={countrySubServices}
            handleToggleSearch={handleToggleSearch}
            pageData={pageData}
          />
        )}
    </div>
  )
}

LocalSearch.propTypes = {
  country: string,
  data: objectOf(),
  isMultiLang: bool,
  navLocale: string,
  searchPlaceholder: string,
  setFocus: func,
  show: bool,
}

LocalSearch.defaultProps = {
  country: '',
  data: {},
  isMultiLang: false,
  navLocale: '',
  searchPlaceholder: '',
  setFocus: null,
  show: false,
}

export default LocalSearch
