import {useEffect, useState} from 'react'

export default function useFetchObject(url) {
  const [state, setState] = useState(null)

  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(response => setState(response))
  }, [])

  return [state, setState]
}
