import {
  arrayOf,
  bool,
  boolean,
  func,
  instanceOf,
  node,
  number,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'

/* GENERIC PROPTYPES */

export const countryPropTypes = {
  countryCode: string.isRequired,
  id: string.isRequired,
  language: arrayOf(
    shape({
      id: string.isRequired,
      localCode: string.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
  name: string.isRequired,
  technicalName: string.isRequired,
  urlPrefix: string.isRequired,
}

export const continentPropTypes = {
  id: string.isRequired,
  name: string.isRequired,
  node_locale: string.isRequired,
  slug: string.isRequired,
  countries: arrayOf(shape(countryPropTypes)).isRequired,
}

export const reorderedContinentsPropTypes = arrayOf(
  shape(continentPropTypes),
).isRequired

export const mediaPropTypes = shape({
  file: shape({
    contentType: string,
    fileName: string,
    url: string,
  }),
  fluid: shape({
    fluid: string,
    aspectRatio: number,
    base64: string,
    sizes: string,
    src: string,
    srcSet: string,
    srcSetWebp: string,
    srcWebp: string,
  }),
})

export const mediaPropTypesDefault = shape({
  file: shape({
    contentType: '',
    fileName: '',
    url: '',
  }),
  fluid: shape({
    fluid: '',
    aspectRatio: '',
    base64: '',
    sizes: '',
    src: '',
    srcSet: '',
    srcSetWebp: '',
    srcWebp: '',
  }),
})

export const locationPropTypes = shape({
  hash: string,
  host: string,
  hostname: string,
  href: string,
  key: string,
  origin: string,
  pathname: string,
  port: string,
  protocol: string,
  search: string,
})

export const mediaMetaPropTypes = shape({
  description: string,
  title: string,
})

export const imageFluidPropTypes = shape({
  fluid: shape({
    src: string,
  }),
  mediaMetaPropTypes,
})

export const imageFixedPropTypes = shape({
  fixed: shape({
    src: string,
  }),
  mediaMetaPropTypes,
})

export const svgOrVideoPropTypes = shape({
  file: shape({
    url: string,
    contentType: string,
  }),
})

export const simpleImagePropTypes = shape({
  file: shape({
    url: string,
  }),
})

export const metaPage = shape({
  metaTitle: string,
  metaDescription: shape({metaDescription: string}),
})

export const longTextPropTypes = shape({
  childMarkdownRemark: shape({
    html: string,
  }),
})

export const richTextPropTypes = shape({
  raw: string,
})

export const dropDownLinkPropTypes = shape({
  menuTitle: string,
  menuLink: string,
  slug: string,
  childMenu: arrayOf(
    objectOf(
      shape({
        link: string,
        title: string,
      }),
    ),
  ),
  prefix: string,
})

export const socialPropTypes = shape({
  hasBorderRadius: boolean,
  hasMarginTop: boolean,
  hasPrimaryColor: boolean,
  isCentered: boolean,
  isLarge: boolean,
  isSpaced: boolean,
  socialMediaLinks: arrayOf(
    shape({
      id: string,
      link: string,
      icon: svgOrVideoPropTypes,
    }),
  ),
})

export const countriesByLocalesCfPropTypes = shape({
  continent: arrayOf(shape({name: string})),
  language: arrayOf(
    shape({
      name: string,
      id: string,
      localCode: string,
      localeName: string,
    }),
  ),

  name: string,
  node_locale: string,
  urlPrefix: string,
})

export const collapseMenuPropTypes = shape({
  id: string,
  prefix: string,
  node: objectOf(
    shape({
      title: string,
      menuItems: arrayOf(
        objectOf(
          shape({
            link: string,
            id: string,
            title: string,
          }),
        ),
      ),
    }),
  ),
  countryCode: string,
  hasShortPath: bool,
})
export const footerPropTypes = shape({
  menuItemsLists: arrayOf(
    shape({
      menuItems: shape({
        link: string,
        title: string,
        id: string,
      }),
      id: string,
      title: string,
    }),
  ),
  copyright: string,
  legal: shape({
    id: string,
    link: string,
    title: string,
  }),
  social: shape({
    title: string,
    linkTo: string,
  }),
  countriesContact: arrayOf(
    shape({
      adress: arrayOf(string),
      country: string,
      fax: arrayOf(string),
      phone: arrayOf(string),
      title: string,
      id: string,
    }),
  ),
  fax: string,
  phone: string,
})

export const menuItemsQueryPropTypes = shape({
  fulfillmentValue: shape({
    data: shape({
      allContentfulMenu: shape({
        edges: arrayOf(
          objectOf(
            shape({
              node: objectOf(
                shape({
                  menuItems: objectOf(
                    shape({
                      link: string,
                      subMenu: arrayOf(
                        shape({
                          link: string,
                          title: string,
                        }),
                      ),
                      title: string,
                    }),
                  ),
                  logo: shape({
                    title: string,
                    fixed: shape({
                      src: string,
                    }),
                  }),
                  footer: footerPropTypes,
                }),
              ),
            }),
          ),
        ),
      }),
    }),
    nodeLocale: string,
    slug: string,
  }),
})

export const configSlugPropTypes = shape({
  configSlug: objectOf(
    oneOfType([
      shape({
        'en-US': string,
        fr: string,
      }),
      arrayOf(
        shape({
          node: shape({
            code: string,
            default: boolean,
            fallbackCode: string,
            id: string,
            name: string,
          }),
        }),
      ),
    ]),
  ),
})

export const pageContextPropTypes = shape({
  configSlug: configSlugPropTypes,
  contentId: string,
  menuItemsQuery: menuItemsQueryPropTypes,
})

export const layoutPropTypes = shape({
  children: node,
  pageContext: pageContextPropTypes,
  socialMediaLinks: socialPropTypes,
  hasScrollTop: boolean,
})

export const SwitchLanguagePropTypes = shape({
  currentCountry: string,
  currentNodeLocale: string,
  configSlug: configSlugPropTypes,
  continents: arrayOf(
    objectOf(
      shape({
        countries: node,
        id: string,
        name: string,
      }),
    ),
  ),
  setIsModalLangOpen: func,
})

export const aboutUsPropTypes = shape({
  data: objectOf(
    shape({
      homepageTitle: string,
      name: string,
      homepageItem3Title: string,
      homepageItem3ShortDescription: string,
      homepageItem3: oneOfType([imageFluidPropTypes, imageFluidPropTypes]),
      homepageItem2Title: string,
      homepageItem2ShortDescription: string,
      homepageItem2: oneOfType([imageFluidPropTypes, imageFluidPropTypes]),
      homepageItem1Title: string,
      homepageItem1ShortDescription: string,
      homepageItem1: oneOfType([imageFluidPropTypes, imageFluidPropTypes]),
      callToAction: string,
      slug: string,
    }),
  ),
})

export const allContentfulDocumentPropTypes = shape({
  edges: arrayOf(
    shape({
      node: shape({
        id: string,
        name: string,
        documentType: shape({
          id: string,
          name: string,
        }),
        file: shape({
          id: string,
          name: string,
          file: shape({
            url: string,
          }),
        }),
      }),
    }),
  ),
})

export const allContentfulDownloadPagePropTypes = shape({
  nodes: arrayOf(
    shape({
      byTypeLabel: string,
      downloadCta: string,
      metaTitle: string,
      pageTitle: string,
      searchLabel: string,
      shortDescription: shape({
        shortDescription: string,
      }),
      metaDescription: shape({
        metaDescription: string,
      }),
      documents: arrayOf({
        createdAt: string,
        name: string,
        documentType: shape({
          name: string,
          file: shape({
            id: string,
            name: string,
            file: shape({
              url: string,
            }),
          }),
        }),
      }),
    }),
  ),
})

export const financialPostsPropTypes = shape({
  byTypeLabel: string,
  downloadCta: string,
  searchLabel: string,
  allDocuments: arrayOf({
    createdAt: string,
    name: string,
    documentType: shape({
      name: string,
      file: shape({
        id: string,
        name: string,
        file: shape({
          url: string,
        }),
      }),
    }),
  }),
})

export const downloadPagePropTypes = shape({
  data: objectOf(
    shape({
      allContentfulDocument: allContentfulDocumentPropTypes,
      allContentfulDownloadPage: allContentfulDownloadPagePropTypes,
    }),
  ),
})

export const contactUsPropTypes = shape({
  data: objectOf(
    shape({
      title: string,
      description: string,
      firstnamePlaceholder: string,
      lastnamePlaceholder: string,
      emailPlaceholder: string,
      phonePlaceholder: string,
      companyPlaceholder: string,
      messagePlaceholder: string,
      callToAction: string,
      icon: string,
      metaDescription: string,
      metaTitle: string,
      phoneLabel: string,
      prefix: string,
      faxLabel: string,
    }),
  ),
})

export const carouselPropTypes = shape({
  carousel: arrayOf(
    shape({
      title: string,
      description: string,
      buttonLabel: string,
      buttonLink: string,
      image: svgOrVideoPropTypes,
    }),
  ),
  socialMediaLinks: socialPropTypes,
})

export const followUsPropTypes = shape({
  data: objectOf(
    shape({
      apiKey: string,
      followUsName: string,
      followUsShortDescription: shape({
        followUsShortDescription: string,
      }),
    }),
  ),
})

export const insightCardsPropTypes = shape({
  country: string,
  createdAt: string,
  id: string,
  image: imageFluidPropTypes,
  node_locale: string,
  slug: string,
  title: string,
  type: shape({
    name: string,
  }),
})

export const insightsPropTypes = shape({
  carousel: shape({
    edges: arrayOf(
      objectOf(
        shape({
          node: objectOf(insightCardsPropTypes),
        }),
      ),
    ),
  }),
  data: shape({
    callToAction: string,
    node_locale: string,
    name: string,
    homepageTitle: string,
  }),
})

export const playbookPropTypes = shape({
  data: objectOf(
    shape({
      buttonLink: string,
      buttonTitle: string,
      description: string,
      tag: string,
      title: string,
      setList: objectOf(
        shape({
          /* create RichtextPropTypes for title ! */
          title: string,
          slug: string,
          image: oneOfType([imageFluidPropTypes, imageFluidPropTypes]),
        }),
      ),
    }),
  ),
})

export const joinUsPropTypes = shape({
  data: objectOf(
    shape({
      callToAction: string,
      callToActionUrl: string,
      workingAtKeyrusImage: imageFluidPropTypes,
      workingAtKeyrusTitle: string,
      workingAtKeyrusDescription: shape({workingAtKeyrusDescription: string}),
      employeeTestimonyImage: imageFluidPropTypes,
      employeeTestimonyName: string,
      employeeTestimonyJobTitle: string,
      employeeTestimonyDescription: shape({
        employeeTestimonyDescription: string,
      }),
      employeeTestimonyCallToAction: string,
      employeeTestimonyCallToActionUrl: string,
      homepageTitle: string,
      name: string,
      shortDescription: shape({shortDescription: string}),
    }),
  ),
  link: string,
  linkIsInternal: bool,
  logo: shape({
    title: string,
    file: shape({
      contentType: string,
      fileName: string,
      url: string,
    }),
  }),
})

export const manifestoPropTypes = shape({
  data: shape({
    shortDescription: longTextPropTypes,
    url: string,
    slug: string,
    callToAction: string,
    title: string,
    name: string,
    image: oneOf([imageFixedPropTypes, imageFluidPropTypes]),
    coreValues: arrayOf(
      objectOf(
        shape({
          title: string,
          longDescription: richTextPropTypes,
          icon: oneOf([imageFixedPropTypes, imageFluidPropTypes]),
        }),
      ),
    ),
    contentsSections1: arrayOf(
      objectOf(
        shape({
          title: string,
          longDescription: richTextPropTypes,
          icon: oneOf([imageFixedPropTypes, imageFluidPropTypes]),
        }),
      ),
    ),
    coreValue1Icon: svgOrVideoPropTypes,
    coreValue1LongDescription: string,
    coreValue1Title: string,
    coreValue2Icon: svgOrVideoPropTypes,
    coreValue2LongDescription: string,
    coreValue2Title: string,
    coreValue3Icon: svgOrVideoPropTypes,
    coreValue3LongDescription: string,
    coreValue3Title: string,
    tooltipHumanData: {tooltipHumanData: string},
    tooltipInspirationalReality: {tooltipInspirationalReality: string},
    tooltipSimplexFuture: {tooltipSimplexFuture: string},
  }),
})

export const subLevelServicesPropTypes = arrayOf(
  shape({
    // eslint-disable-next-line camelcase
    contentful_id: string,
    id: string,
    image: oneOfType([svgOrVideoPropTypes, imageFluidPropTypes]),
    imageOrIcon: oneOfType([svgOrVideoPropTypes, imageFluidPropTypes]),
    insight: arrayOf(shape({id: string})),
    metaDescription: shape({metaDescription: string}),
    metaTitle: string,
    name: string,
    shortDescription: shape({shortDescription: string}),
    slug: string,
  }),
)

export const topLevelServicesPropTypes = arrayOf(
  shape({
    // eslint-disable-next-line camelcase
    contentful_id: string,
    id: string,
    image: oneOfType([svgOrVideoPropTypes, imageFluidPropTypes]),
    imageOrIcon: oneOfType([svgOrVideoPropTypes, imageFluidPropTypes]),
    indicatorColor: string,
    insight: arrayOf(shape({id: string})),
    metaDescription: shape({metaDescription: string}),
    metaTitle: string,
    name: string,
    shortDescription: shape({shortDescription: string}),
    slug: string,
    subLevelServices: subLevelServicesPropTypes,
    subLevelServicesIds: arrayOf(
      shape({
        entry: string,
      }),
    ),
  }),
)

export const servicesPropTypes = shape({
  homepageTitle: string,
  metaTitle: string,
  name: string,
  pagetitle: string,
  shortDescription: shape({
    shortDescription: string,
  }),
  topLevelServices: arrayOf(
    shape({
      imageOrIcon: imageFluidPropTypes,
      metaTitle: string,
      name: string,
      shortDescription: shape({
        shortDescription: string,
      }),
      subLevelServices: shape({
        metaTitle: string,
        imageOrIcon: imageFluidPropTypes,
        name: string,

        shortDescription: shape({
          shortDescription: string,
        }),
      }),
    }),
  ),
})

export const servicesUiPropTypes = shape({
  homepageTitle: string,
  metaTitle: string,
  name: string,
  pagetitle: string,
  shortDescription: shape({
    shortDescription: string,
  }),
})

export const relatedPropTypes = shape({
  keyPlays: arrayOf(objectOf({node: oneOf([insightCardsPropTypes, node])})),
  title: string,
  description: string,
  ctaTitle: string,
  ctaLink: string,
  cardSize: string,
  buttonsPosition: string,
  isServices: boolean,
})

/* INSIGHTS */
export const allContentfulInsightTypePropTypes = shape({
  edges: arrayOf(
    shape({
      node: shape({
        name: string,
      }),
    }),
  ),
})

export const allContentfulIndustryPropTypes = shape({
  edges: arrayOf(
    shape({
      node: shape({
        name: shape({
          name: string,
        }),
      }),
    }),
  ),
})

export const blogListPropTypes = shape({
  nodes: shape({
    externalLink: string,
    publicationDate: string,
    node_locale: string,
    image: imageFluidPropTypes,
    metaTitle: shape({
      metaTitle: string,
    }),
    metaDescription: shape({
      metaDescription: string,
    }),
    type: shape({
      name: string,
    }),
    country: string,
    slug: string,
  }),
  industry: allContentfulIndustryPropTypes,
  types: allContentfulInsightTypePropTypes,
  searchPlaceholder: string,
  filterByIndustryLabel: string,
  filterByServiceLabel: string,
  filterByTypeLabel: string,
  filterSeeMoreLabel: string,
  filterSeeLessLabel: string,
})

export const heroInsightsPropTypes = shape({
  featuredTagLabel: string,
  pageTitle: string,
  shortDescription: string,
  nodes: arrayOf(
    shape({
      publicationDate: string,
      metaTitle: shape({
        metaTitle: string,
      }),
      metaDescription: shape({
        metaDescription: string,
      }),
      type: shape({
        name: string,
      }),
      image: imageFluidPropTypes,
      slug: string,
    }),
  ),
  titleVariant: string,
})

/* INSIGHT */
export const allContentfulInsightsPropTypes = shape({
  nodes: arrayOf(
    shape({
      insightBackButtonLabel: string,
      insightShareButtons: arrayOf(
        shape({
          icon: svgOrVideoPropTypes,
        }),
      ),
      newsLetterCallToAction: string,
      newsLetterDescription: string,
      newsLetterImage: svgOrVideoPropTypes,
      newsLetterPlaceholder: string,
      newsLetterTitle: string,
      node_locale: string,
    }),
  ),
})

export const allContentfulInsightPropTypes = shape({
  nodes: arrayOf(
    shape({
      content: shape({
        raw: string,
      }),
      image: imageFluidPropTypes,
      metaTitle: shape({
        metaTitle: string,
      }),
      node_locale: string,
      publicationDate: string,
      type: shape({
        name: string,
      }),
    }),
  ),
})

export const heroInsightPropTypes = shape({
  image: imageFluidPropTypes,
  metaTitle: string,
  type: shape({
    name: string,
  }),
  backButtonLabel: string,
  backButtonHasShortLink: boolean,
  navLocale: string,
})

export const articleInsightPropTypes = shape({
  content: shape({
    raw: string,
  }),
  hasPaddingBottom: boolean,
})

export const heroDetailsPropTypes = shape({
  backLink: string,
  backLabel: string,
  name: string,
  description: shape({description: string}),
  icon: imageFluidPropTypes,
  tags: arrayOf(string),
})

export const subServiceCardPropTypes = shape({
  todo: string,
})

export const heroPropTypes = shape({
  ctaColor: string,
  ctaLink: string,
  ctaTitle: string,
  description: shape({description: string}),
  descriptionType: string,
  heroPropTypes: bool,
  hasRoundCorner: bool,
  hasSmallVerticalMargin: bool,
  hat: string,
  isAlignCenter: bool,
  media: svgOrVideoPropTypes,
  offsetBottom: bool,
  title: string,
  titleType: string,
})

export const brandsCardsPropTypes = shape({
  id: string,
  description: shape({description: string}),
  logo: string,
  name: string,
  website: string,
})

export const brandsListPropTypes = shape({
  callToAction: 'string',
  cards: brandsCardsPropTypes,
})

export const brandsPropTypes = shape({
  pageContext: layoutPropTypes,
  data: shape({
    imageOrVideo: shape({imageFluidPropTypes, svgOrVideoPropTypes}),
    metaPage,
    surtitle: string,
    pageTitle: string,
    shortDescription: shape({shortDescription: string}),
    brands: arrayOf({brandsCardsPropTypes}),
  }),
})

export const cardsManagementListPropTypes = arrayOf(
  objectOf(
    shape({
      id: string,
      jobTitle: string,
      linkedInProfile: string,
      name: string,
      photo: imageFluidPropTypes || simpleImagePropTypes,
    }),
  ),
)

export const managementListPropTypes = shape({
  managementTeams: cardsManagementListPropTypes,
  instaIcon: svgOrVideoPropTypes,
})

export const managementPropTypes = shape({
  data: objectOf(
    shape({
      imageOrVideo: shape({imageFluidPropTypes, svgOrVideoPropTypes}),
      metaTitle: string,
      metaDescription: shape({metaDescription: string}),
      surtitle: string,
      pageTitle: string,
      shortDescription: shape({shortDescription: string}),
      managementListPropTypes,
    }),
  ),
})

export const kpisListPropTypes = shape({
  data: objectOf(
    shape({
      kpi1Icon: svgOrVideoPropTypes,
      kpi1Number: string,
      kpi1ShortDescription: string,
      kpi2Icon: svgOrVideoPropTypes,
      kpi2Number: string,
      kpi2ShortDescription: string,
      kpi3Icon: svgOrVideoPropTypes,
      kpi3Number: string,
      kpi3ShortDescription: string,
      kpi4Icon: svgOrVideoPropTypes,
      kpi4Number: string,
      kpi4ShortDescription: string,
    }),
  ),
})

export const contentSectionPropTypes = shape({
  hatTitle: string,
  title: string,
  callToAction: string,
  variation: oneOf(['Image left', 'Image right', 'No image', 'No content']),
  description: shape({raw: string}),
  image: oneOfType([imageFluidPropTypes, svgOrVideoPropTypes]),
  isLogo: bool,
  showSection: bool,
})

export const aboutUsMainPropTypes = shape({
  pageContext: layoutPropTypes,
  data: objectOf(
    shape({
      imageOrVideo: oneOfType([imageFluidPropTypes, svgOrVideoPropTypes]),
      metaTitle: string,
      pageTitle: string,
      surtitle: string,
      shortDescription: shape({shortDescription: string}),
      callToAction: string,
      callToActionLinkedPage: shape({slug: string}),
      kpi1Number: string,
      kpi1ShortDescription: string,
      kpi1Icon: string,
      kpi2Number: string,
      kpi2ShortDescription: string,
      kpi2Icon: string,
      kpi3Number: string,
      kpi3ShortDescription: string,
      kpi3Icon: string,
      kpi4Number: string,
      kpi4ShortDescription: string,
      kpi4Icon: string,
      metaDescription: shape({metaDescription: string}),
      pillarsItem1: string,
      pillarsItem2: string,
      pillarsItem3: string,
      pillarsItem4: string,
      pillarsItem5: string,
      pillarsTitle: string,
      contentSections1: contentSectionPropTypes,
      contentSections2: contentSectionPropTypes,
      contentSections3: contentSectionPropTypes,
    }),
  ),
})

export const CompanyPurposePropTypes = shape({
  pageContext: pageContextPropTypes,
  data: objectOf(
    shape({
      node_locale: string,
      metaTitle: string,
      video: svgOrVideoPropTypes,
      title: string,
      longDescription: shape({
        raw: string,
      }),
      contentsSections1: shape({
        image: svgOrVideoPropTypes,
        variation: string,
        description: shape({
          raw: string,
        }),
        title: string,
      }),
    }),
  ),
})

/* Ethics Complicance PropTypes */

export const ethicsTextBoxPropTypes = shape({
  contents: arrayOf(contentSectionPropTypes),
})

export const ethicsCompliancePropTypes = shape({
  data: objectOf(
    shape({
      contentSections1: contentSectionPropTypes,
      contentSections2: contentSectionPropTypes,
      imageOrVideo: svgOrVideoPropTypes,
      metaDescription: shape({
        metaDescription: string,
      }),
      metaTitle: string,
      pageTitle: string,
      shortDescription: shape({
        shortDescription: string,
      }),
      surtitle: string,
    }),
  ),
  pageContext: pageContextPropTypes,
})

export const kpisPropTypes = shape({
  data: shape({icon: svgOrVideoPropTypes, fullSentence: string}),
  title: string,
})

export const locationFrontMatterPropTypes = shape({
  name: string,
  id: string,
  address: shape({address: string}),
  latitude: string,
  longitude: string,
  photo: oneOfType([imageFluidPropTypes, imageFluidPropTypes]),
  index: string,
  phone: string,
  fax: string,
})

export const cardsListPropTypes = shape({
  data: locationFrontMatterPropTypes,
  applyView: func,
  itemsRef: oneOfType([func, shape({current: instanceOf(string)})]),
  phoneText: string,
  faxText: string,
  country: string,
})
export const navButtonsPropTypes = shape({
  continents: arrayOf(shape({slug: string, name: string, location: string})),
  hash: string,
  onNavButtonClick: func,
})

export const worldMapPropTypes = shape({
  markers: arrayOf(shape({locationFrontMatterPropTypes})),
  continents: node,
  phone: string,
  fax: string,
  popupButtonTitle: string,
  pins: oneOfType([imageFluidPropTypes, imageFluidPropTypes]),
  hasNav: bool,
  legend: string,
})

export const mapMarkerPropTypes = shape({
  line: locationFrontMatterPropTypes,
  phoneText: string,
  faxText: string,
  pins: oneOfType([imageFluidPropTypes, imageFluidPropTypes]),
  refProp: oneOfType([func, shape({current: instanceOf(node)})]),
})

export const locationsPropTypes = shape({
  pageContext: layoutPropTypes,
  data: shape({
    imageOrVideo: shape({imageFluidPropTypes, svgOrVideoPropTypes}),
    metaTitle: string,
    metaDescription: shape({metaDescription: string}),
    pageTitle: string,
    shortDescription: shape({shortDescription: string}),
    surtitle: string,
    faxLabel: string,
    phoneLabel: string,
    mapTitle: string,
    kpi: kpisPropTypes,
    mapMarker: mapMarkerPropTypes,
  }),
})

export const worldwidePropTypes = shape({
  countryNameForContactForm: string,
  ctaColor: string,
  data: objectOf(
    shape({
      name: string,
      pageTitle: string,
      mapMarker: svgOrVideoPropTypes,
      phoneLabel: string,
      faxLabel: string,
      callToAction: string,
      kpi: kpisPropTypes,
      offices: mapMarkerPropTypes,
    }),
  ),
  legend: string,
  locations: mapMarkerPropTypes,
  siteUrl: string,
})

// keyplay

export const socialSharePropTypes = shape({
  title: string,
  label: string,
  siteURL: string,
})

/* KEYPLAY PROPTYPES */

export const allContentfulHomepagePropTypes = shape({
  edges: arrayOf(
    shape({
      nodes: shape({
        id: string,
        socialMediaLinks: shape({
          id: string,
          name: string,
          link: string,
          icon: shape({
            file: shape({
              url: string,
            }),
          }),
        }),
      }),
    }),
  ),
})
export const allContentfulPlaybookPropTypes = shape({
  edges: arrayOf(
    shape({
      node: shape({
        keyPlayCustomerTestimonyLabel: string,
        keyPlayShareLabel: string,
        keyPlayApproachLabel: string,
        keyPlayBenefitsLabel: string,
        keyPlayBackButtonLabel: string,
        keyPlayChallengeLabel: string,
      }),
    }),
  ),
})
export const allContentfulKeyPlayPropTypes = shape({
  edges: arrayOf(
    shape({
      node: shape({
        id: number,
        childContentfulKeyPlayApproachTextNode: shape({
          approach: string,
          internal: shape({
            content: string,
            description: string,
          }),
        }),
        childContentfulKeyPlayBenefitsTextNode: shape({
          benefits: string,
          internal: shape({
            content: string,
            description: string,
          }),
        }),
        childContentfulKeyPlayChallengeTextNode: shape({
          challenge: string,
          internal: shape({
            content: string,
            description: string,
          }),
        }),
        childContentfulKeyPlayKeyResult1TextNode: shape({
          keyResult1: string,
        }),
        childContentfulKeyPlayKeyResult2TextNode: shape({
          keyResult2: string,
        }),
        childContentfulKeyPlayKeyResult3TextNode: shape({
          keyResult3: string,
        }),
        childContentfulKeyPlayMetaTitleTextNode: shape({
          metaTitle: string,
        }),
        childContentfulKeyPlayMetaDescriptionTextNode: shape({
          metaDescription: string,
        }),
        childContentfulKeyPlayBackgroundTextNode: shape({
          background: string,
        }),
        childContentfulKeyPlayTitleTextNode: shape({
          title: string,
        }),
        image: oneOfType(imageFluidPropTypes),
      }),
    }),
  ),
})

/* COMPONENT PROPTYPES */

export const keyPlayHeaderPropTypes = shape({
  buttonLabel: string,
  image: shape({
    aspectRatio: number,
    base64: string,
    src: string,
    srcSet: string,
    srcSetWebp: string,
    srcWebp: string,
  }),
  title: string,
  allPartnerNames: arrayOf(string),
})

export const keysPropTypes = shape({
  title: string,
  keys1: string,
  keys2: string,
  keys3: string,
})

export const partnersPropTypes = shape({
  partner: arrayOf(
    objectOf(
      shape({
        kpi1Description: shape({
          kpi1Description: string,
        }),
        kpi2Description: shape({
          kpi2Description: string,
        }),
        kpi3Description: shape({
          kpi3Description: string,
        }),
        kpi1Number: shape({
          kpi1Number: string,
        }),
        kpi2Number: shape({
          kpi2Number: string,
        }),
        kpi3Number: shape({
          kpi3Number: string,
        }),
        logo: shape({
          contentType: string,
          fileName: string,
          url: string,
        }),
        name: string,
        shortDescription: shape({
          shortDescription: string,
        }),
      }),
    ),
  ),
  title: string,
})

export const homepagePartnerSectionPropTypes = shape({
  ctaTitle: string,
  title: string,
  hat: string,
  data: partnersPropTypes,
  pageContext: pageContextPropTypes,
})

export const keyPlaySectionPropTypes = shape({
  title: string,
  content: string,
})

export const keyPlayTestimonialPropertyTypes = shape({
  title: string,
  data: objectOf(
    shape({
      name: string,
      position: string,
      company: shape({
        company: string,
      }),
      testimonial: shape({
        testimonial: string,
      }),
    }),
  ),
})

/* PLAYBOOK PROPTYPES */

export const FilterPropTypes = shape({
  array: arrayOf(
    objectOf(
      shape({
        name: string,
      }),
    ),
  ),
  filterLabel: string,
  filterSeeMoreLabel: string,
  filterSeeLessLabel: string,
  hasLongText: boolean,
})

export const FiltersContainerPropTypes = shape({
  hasBorder: boolean,
  searchPlaceholder: string,
})

export const keyplaysPropTypes = shape({
  updateTags: func,
  togglePartner: func,
  isAllPartnerToggle: bool,
  allContentfulPartner: arrayOf(
    objectOf(
      shape({
        externalLink: string,
        url: string,
        id: string,
        // eslint-disable-next-line camelcase
        key_play: arrayOf(
          objectOf(
            shape({
              id: string,
            }),
          ),
        ),
        title: objectOf(
          shape({
            title: string,
          }),
        ),
        kpi1Description: objectOf(
          shape({
            kpi1Description: string,
          }),
        ),
        kpi1Number: objectOf(
          shape({
            kpi1Number: string,
          }),
        ),
        kpi2Description: objectOf(
          shape({
            kpi1Description: string,
          }),
        ),
        kpi2Number: objectOf(
          shape({
            kpi1Number: string,
          }),
        ),
        kpi3Description: objectOf(
          shape({
            kpi1Description: string,
          }),
        ),
        kpi3Number: objectOf(
          shape({
            kpi1Number: string,
          }),
        ),
        shortDescription: objectOf(
          shape({
            shortDescription: string,
          }),
        ),
        logo: objectOf(
          shape({
            file: objectOf(
              shape({
                contentType: string,
                fileName: string,
                url: string,
              }),
            ),
            fluid: objectOf(
              shape({
                src: string,
              }),
            ),
          }),
        ),
      }),
    ),
  ),
  itemsToRender: arrayOf(
    objectOf(
      shape({
        slug: string,
        title: objectOf(
          shape({
            title: string,
          }),
        ),
        image: objectOf(
          shape({
            fluid: objectOf(
              shape({
                src: string,
              }),
            ),
          }),
        ),
      }),
    ),
  ),
})

export const partnerHeaderPropTypes = shape({
  partner: string,
})

export const playbookHeaderPropTypes = shape({
  pageTitle: string,
  description: objectOf(
    shape({
      shortDescription: string,
    }),
  ),
  eulaLink: string,
  hasEulaLink: boolean,
})

export const switchHeaderPropTypes = shape({
  isPartnerToggle: boolean,
  partner: objectOf(
    objectOf(
      shape({
        kpi1Description: shape({
          kpi1Description: string,
        }),
        kpi2Description: shape({
          kpi2Description: string,
        }),
        kpi3Description: shape({
          kpi3Description: string,
        }),
        kpi1Number: shape({
          kpi1Number: string,
        }),
        kpi2Number: shape({
          kpi2Number: string,
        }),
        kpi3Number: shape({
          kpi3Number: string,
        }),
        logo: shape({
          contentType: string,
          fileName: string,
          url: string,
        }),
        name: string,
        shortDescription: shape({
          shortDescription: string,
        }),
      }),
    ),
  ),
  pageTitle: string,
  description: objectOf(
    shape({
      shortDescription: string,
    }),
  ),
})

/* COMPONENT PROPTYPES */

export const simpleAccordionPropTypes = shape({
  tagsArray: arrayOf(
    objectOf(
      shape({
        name: string,
      }),
    ),
  ),
  filterSeeMoreLabel: string,
  filterSeeLessLabel: string,
})

export const industryFilterPropTypes = shape({
  allContentfulIndustry: arrayOf(
    objectOf(
      shape({
        name: string,
      }),
    ),
  ),
  filterSeeMoreLabel: string,
  filterSeeLessLabel: string,
  filterByIndustryLabel: string,
})

export const partnersFilterPropTypes = shape({
  allContentfulPartner: arrayOf(
    objectOf(
      shape({
        name: string,
      }),
    ),
  ),
  filterSeeMoreLabel: string,
  filterSeeLessLabel: string,
  filterByPartnerLabel: string,
  filterByPartnerDefaultFilter: string,
})

export const serviceFilterPropTypes = shape({
  allContentfulTopLevelService: arrayOf(
    objectOf(
      shape({
        name: string,
      }),
    ),
  ),
  allContentfulSubLevelService: arrayOf(
    objectOf(
      shape({
        name: string,
      }),
    ),
  ),
  filterSeeMoreLabel: string,
  filterSeeLessLabel: string,
  filterByServiceLabel: string,
})

export const tagButtonPropTypes = shape({
  tagData: string,
  currentTag: arrayOf(string),
  updateTags: Function.prototype,
})

/* INVESTORS PROPTYPES */

export const pieChartsDataPropTypes = shape({
  name: string,
  valueThisYear: number,
  valueLastYear: number,
})

export const pieChartsPropTypes = shape({
  keyFiguresBreakdownTitle: string,
  keyFiguresBreakdownMarketSegmentTitle: string,
  keyFiguresBreakdownLegend: richTextPropTypes,
  keyFiguresBreakdownIndustryTitle: string,
  keyFiguresBreakdownGeographicalTitle: string,
  keyFiguresData: objectOf(
    shape({
      byGeographicArea: arrayOf(pieChartsDataPropTypes),
      byIndustry: arrayOf(pieChartsDataPropTypes),
      byMarketSegment: arrayOf(pieChartsDataPropTypes),
    }),
  ),
})

export const mainCardPropTypes = shape({
  description: richTextPropTypes,
  ceo: shape({
    jobTitle: string,
    linkedInProfile: string,
    name: string,
    photo: simpleImagePropTypes,
  }),
})

export const eventsAndDocumentsPropTypes = shape({
  eventsTitle: string,
  eventsMoreLabel: string,
  eventsNextLabel: string,
  eventsLessLabel: string,
  downloadCta: string,
  downloadMoreLabel: string,
  eventsItems: arrayOf(
    shape({
      date: string,
      name: string,
    }),
  ),
})

export const capitalStructurePropTypes = shape({
  numberOfShares: number,
  numberOfVotingRights: number,
  percentageOfCapital: number,
  percentageOfVotingRights: number,
  shareholder: string,
})

export const consolidatedRevenuePropTypes = arrayOf(
  shape({
    consolidatedRevenue: number,
    year: number,
  }),
)

export const operatingResultPropTypes = arrayOf(
  shape({
    operatingResult: number,
    year: number,
  }),
)

export const headcountPropTypes = arrayOf(
  shape({
    headcount: number,
    year: number,
  }),
)

export const keyFiguresDataPropTypes = shape({
  breakdown: objectOf(
    shape({
      byGeographicArea: arrayOf(pieChartsDataPropTypes),
      byIndustry: arrayOf(pieChartsDataPropTypes),
      byMarketSegment: arrayOf(pieChartsDataPropTypes),
    }),
  ),
  consolidatedRevenue: consolidatedRevenuePropTypes,
  operatingResult: operatingResultPropTypes,
  headcounts: headcountPropTypes,
})

export const investorsPropTypes = shape({
  data: objectOf(
    shape({
      contentfulInvestors: objectOf(
        shape({
          callToAction: string,
          capitalStructureData: arrayOf(capitalStructurePropTypes),
          capitalStructureHeader: richTextPropTypes,
          capitalStructureLegend: string,
          capitalStructureTableHeaders: arrayOf(string),
          contentSections: arrayOf(
            shape({
              callToAction: string,
              description: string,
              image: simpleImagePropTypes,
              link: string,
              title: string,
              variation: string,
            }),
          ),
          downloadCta: string,
          downloadMoreLabel: string,
          eventsItems: arrayOf(
            shape({
              date: string,
              name: string,
            }),
          ),
          eventsLessLabel: string,
          eventsMoreLabel: string,
          eventsNextLabel: string,
          eventsTitle: string,
          financialInformationEvents: richTextPropTypes,
          imageOrVideo: simpleImagePropTypes,
          investorsRelationsContactsEmail: string,
          investorsRelationsContactsPhone: richTextPropTypes,
          investorsRelationsContactsTitle: string,
          investorsRelationsHeader: richTextPropTypes,
          investorsRelationsInfosGeneralMeetings: richTextPropTypes,
          investorsRelationsInfosSecDepartment: richTextPropTypes,
          investorsRelationsInfosTitle: string,
          investorsRelationsMyKeyrusLink: richTextPropTypes,
          investorsRelationsMyKeyrusLinkCta: string,
          investorsRelationsMyKeyrusLinkCtaLink: string,
          keyFiguresBreakdownGeographicalTitle: string,
          keyFiguresBreakdownIndustryTitle: string,
          keyFiguresBreakdownLegend: richTextPropTypes,
          keyFiguresBreakdownMarketSegmentTitle: string,
          keyFiguresBreakdownTitle: string,
          keyFiguresData: keyFiguresDataPropTypes,
          keyFiguresHeadcountTitle: string,
          keyFiguresRevenuesLegend: string,
          keyFiguresRevenuesTitle: string,
          keyrusStockBlock1: richTextPropTypes,
          keyrusStockBlock2: richTextPropTypes,
          keyrusStockBlock3: richTextPropTypes,
          keyrusStockImage: imageFluidPropTypes,
          keyrusStockTitle: string,
          metaDescription: shape({metaDescription: string}),
          metaTitle: string,
          pageTitle: string,
          shortDescription: shape({shortDescription: string}),
          speechCeo: cardsManagementListPropTypes,
          speechContent: string,
        }),
      ),
    }),
  ),
})

export const capitalStructureComponentPropTypes = shape({
  capitalStructure: arrayOf(capitalStructurePropTypes),
  capitalStructureTableHeaders: arrayOf(string),
  capitalStructureLegend: string,
  capitalStructureHeader: richTextPropTypes,
})

export const informationInvestorsPropTypes = shape({
  investorsRelationsInfosGeneralMeetings: richTextPropTypes,
  investorsRelationsInfosSecDepartment: richTextPropTypes,
  investorsRelationsInfosTitle: string,
})

export const shareholdersPropTypes = shape({
  investorsRelationsContactsEmail: string,
  investorsRelationsContactsPhone: richTextPropTypes,
  investorsRelationsContactsTitle: string,
  investorsRelationsHeader: richTextPropTypes,
  investorsRelationsMyKeyrusLink: richTextPropTypes,
  investorsRelationsMyKeyrusLinkCta: string,
  investorsRelationsMyKeyrusLinkCtaLink: string,
})

export const stockBlockPropTypes = shape({
  keyrusStockTitle: string,
  keyrusStockImage: imageFluidPropTypes,
  keyrusStockBlock1: richTextPropTypes,
  keyrusStockBlock2: richTextPropTypes,
  keyrusStockBlock3: richTextPropTypes,
})

export const barChartsPropTypes = shape({
  keyFiguresTitle: string,
  keyFiguresRevenuesTitle: string,
  keyFiguresHeadcountTitle: string,
  keyFiguresCurrentOperationalResultsTitle: string,
  keyFiguresData: keyFiguresDataPropTypes,
})

/* RAWTEXT PROPTYPES */
export const rawTextPropTypes = shape({
  isArticle: bool,
  isJustified: boolean,
  shrink: string,
  text: {
    raw: string,
    references: arrayOf(
      shape({
        name: string,
        slug: string,
      }),
    ),
  },
})

/* SIMPLE PAGE PROPTYPES */
export const simplePagePropTypes = shape({
  pageContext: pageContextPropTypes,
  data: shape({
    allContentfulSimplePage: shape({
      nodes: arrayOf(
        shape({
          callToActionLabel: string,
          callToActionUrl: string,
          content: shape({
            raw: string,
          }),
          imageOrVideo: svgOrVideoPropTypes,
          metaDescription: shape({
            metaDescription: string,
          }),
          metaTitle: string,
          pageTitle: string,
          shortDescription: shape({
            shortDescription: string,
          }),
        }),
      ),
    }),
  }),
})

/* CORPORATE GOVERNANCE PROPTYPES */

export const textBoxPropTypes = shape({
  title: string,
  description: shape({
    raw: string,
  }),
})

export const managersPropTypes = arrayOf(
  shape({
    biography: shape({
      raw: string,
    }),
    jobTitle: string,
    linkedInProfile: string,
    name: string,
    photo: svgOrVideoPropTypes,
  }),
)

export const corporateGovernancePropTypes = shape({
  pageContext: pageContextPropTypes,
  data: shape({
    allContentfulCorporateGovernance: shape({
      nodes: arrayOf(
        shape({
          metaTitle: string,
          metaDescription: shape({
            metaDescription: string,
          }),
          pageTitle: string,
          shortDescription: shape({
            shortDescription: string,
          }),
          surtitle: string,
          imageOrVideo: svgOrVideoPropTypes,
          boardOfDirectorsTitle: string,
          boardOfDirectorsContent: shape({
            raw: string,
          }),
          auditCommitteeTitle: string,
          auditCommitteeContent: shape({
            raw: string,
          }),
          managementTeams: arrayOf(
            shape({
              managers: managersPropTypes,
            }),
          ),
        }),
      ),
    }),
  }),
})

export const AwardsPropTypes = shape({
  pageContext: pageContextPropTypes,
  data: shape({
    allContentfulAwards: shape({
      edges: arrayOf(
        shape({
          node: shape({
            name: string,
            pageTitle: string,
            metaTitle: string,
            shortDescription: shape({
              shortDescription: string,
            }),
            metaDescription: shape({
              metaDescription: string,
            }),
          }),
        }),
      ),
    }),
    allContentfulAward: shape({
      edges: arrayOf(
        shape({
          node: shape({
            name: string,
            logo: shape({
              file: shape({
                url: string,
              }),
            }),
            shortDescription: shape({
              shortDescription: string,
            }),
          }),
        }),
      ),
    }),
  }),
})

export const AwardsHeaderPropTypes = shape({
  pageTtile: string,
  description: string,
  selectedAward: shape({
    name: string,
    logo: shape({
      file: shape({
        url: string,
      }),
    }),
    shortDescription: shape({
      shortDescription: string,
    }),
  }),
})

export const AwardsListPropTypes = shape({
  awards: arrayOf(
    shape({
      name: string,
      logo: shape({
        file: shape({
          url: string,
        }),
      }),
      shortDescription: shape({
        shortDescription: string,
      }),
    }),
  ),
})

export const allContentfulCommittedKeyrusPropTypes = shape({
  nodes: arrayOf(
    shape({
      imageOrVideo: shape({
        file: shape({
          contentType: string,
          url: string,
        }),
      }),
      pageTitle: string,
      shortDescription: shape({
        shortDescription: string,
      }),
      surtitle: string,
      contentSection1: shape({
        description: shape({
          raw: string,
        }),
        image: svgOrVideoPropTypes,
        title: string,
        variation: string,
      }),
      contentSectionsTitle: string,
      contentSections: shape({
        callToAction: string,
        description: shape({
          raw: string,
        }),
        image: svgOrVideoPropTypes,
        title: string,
        variation: string,
        link: string,
        typeOfLink: string,
        isOpenInNewWindow: bool,
        fileToDownload: svgOrVideoPropTypes,
      }),
      contentSection2: shape({
        description: shape({
          raw: string,
        }),
        image: shape({
          file: shape({
            url: string,
            contentType: string,
          }),
        }),
        title: string,
        variation: string,
        callToAction: string,
        isOpenInNewWindow: string,
        link: string,
        typeOfLink: string,
      }),
      contentSection3: shape({
        description: shape({
          raw: string,
        }),
        image: svgOrVideoPropTypes,
        title: string,
        variation: string,
      }),
      awards: shape({
        name: string,
        logo: svgOrVideoPropTypes,
      }),
      awardsTitle: string,
      awardsCta: string,
      awardsSurtitle: string,
      kpiTitle: string,
    }),
  ),
})

export const allContentfulLandingPagePropTypes = shape({
  nodes: arrayOf(
    shape({
      title: string,
      iFrameUrl: string,
      height: string,
    }),
  ),
})

export const allContentfulInnovationTechnologiesPropTypes = shape({
  nodes: arrayOf(
    shape({
      callToAction: string,
      imageOrVideo: svgOrVideoPropTypes,
      metaDescription: shape({
        metaDescription: string,
      }),
      metaTitle: string,
      pageTitle: string,
      shortDescription: shape({
        shortDescription: string,
      }),
      surtitle: string,
      contentSections: shape({
        callToAction: string,
        description: shape({
          raw: string,
        }),
        image: svgOrVideoPropTypes,
        title: string,
        variation: string,
        link: string,
        typeOfLink: string,
        isOpenInNewWindow: bool,
        fileToDownload: svgOrVideoPropTypes,
      }),
    }),
  ),
})

export const allContentfulDocumentTemplatePropTypes = shape({
  nodes: arrayOf(
    shape({
      file: shape({
        description: string,
        title: string,
        file: shape({
          url: string,
        }),
      }),
    }),
  ),
})

export const allContentfulLandingPageSystemPropTypes = shape({
  allContentfulLandingPageSystem2: shape({
    nodes: arrayOf(
      shape({
        componentSections: arrayOf(
          shape({
            componentSections: arrayOf(
              shape({
                backgroundImage: mediaPropTypes,
                carousel: shape({
                  carouselItems: arrayOf(
                    shape({
                      cta: string,
                      ctaLink: string,
                      image: mediaPropTypes,
                      primaryText: string,
                      secondaryText: string,
                    }),
                  ),
                }),
                componentSelector: string,
                cta: shape({ctaLink: string, ctaName: string}),
                imageSection: shape({
                  content: string,
                  ctaLink: string,
                  ctaName: string,
                  image: mediaPropTypes,
                  imageContent: string,
                  title: string,
                  variation: string,
                }),
                simpleSection: shape({
                  content: string,
                  title: string,
                }),
              }),
            ),
          }),
        ),
        metaDescription: string,
        metaTitle: string,
        slug: string,
        title: string,
      }),
    ),
  }),
  contentfulCountry: shape({
    countryCode: string,
    isMultilang: bool,
    keywords: arrayOf(string),
    navLocale: string,
    technicalName: string,
    urlPrefix: string,
  }),
  contentfulGenericData: shape({
    genericKeywords: arrayOf({
      content: string,
    }),
  }),
  siteMetaData: shape({
    siteMetaData: {
      defaultDescription: string,
      defaultImage: string,
      defaultTitle: string,
      siteUrl: string,
      titleTemplate: string,
    },
  }),
})

export const switchFormPropTypes = shape({
  callToAction: string,
  companyPlaceholder: string,
  data: shape({
    callToAction: string,
    companyPlaceholder: string,
    description: string,
    emailPlaceholder: string,
    firstnamePlaceholder: string,
    icon: shape({
      file: shape({
        contentType: string,
        fileName: string,
        url: string,
      }),
    }),
    lastnamePlaceholder: string,
    messagePlaceholder: string,
    phonePlaceholder: string,
    termsOfUseConsent: rawTextPropTypes,
    privacyPolicyConsent: rawTextPropTypes,
    title: string,
  }),
  hubSpotCountry: string,
  isArticle: bool,
  isContact: bool,
  locale: string,
  location: locationPropTypes,
  redirection: string,
  titleVariant: string,
  websiteSource: string,
})

export const formPropTypes = shape({
  data: shape({
    callToAction: string,
    companyPlaceholder: string,
    description: string,
    emailPlaceholder: string,
    firstnamePlaceholder: string,
    icon: shape({
      file: shape({
        contentType: string,
        fileName: string,
        url: string,
      }),
    }),
    lastnamePlaceholder: string,
    messagePlaceholder: string,
    phonePlaceholder: string,
    termsOfUseConsent: rawTextPropTypes,
    privacyPolicyConsent: rawTextPropTypes,
    title: string,
  }),
  isArticle: bool,
  isContact: bool,
  redirection: string,
  websiteSource: string,
})
export const partnersListPropTypes = shape({
  hasPartnersName: bool,
  partners: arrayOf(partnersPropTypes),
  selectedPartner: partnersPropTypes,
  setSelectedPartner: func,
})

export const technologyPartnersHeaderPropTypes = shape({
  pageTitle: string,
  description: string,
  eulaCallToAction: string,
})

export const technologyPartnersHeaderKmPropTypes = shape({
  description: string,
  logo: string,
  pageTitle: string,
  subDescription: string,
  subtitle: string,
  surtitle: string,
  topDescription: string,
})

export const technologyPartnerHeaderPropTypes = shape({
  countryData: shape({
    technicalName: string,
  }),
  discoverMoreCtaDefaultLabel: string,
  partner: partnersPropTypes,
})

export const partnerContentPropTypes = shape({
  partner: partnersListPropTypes,
  insights: arrayOf(insightCardsPropTypes),
  keyplays: arrayOf(keyplaysPropTypes),
  countryData: shape({
    technicalName: string,
  }),
  formData: formPropTypes,
  location: locationsPropTypes,
  siteURL: string,
})

export const allContentfulGroupPropTypes = {
  contentfulGroup: shape({
    title: string,
    surtitle: string,
    slug: string,
    metaTitle: string,
    metaDescription: shape({
      metaDescription: string,
    }),
    shortDescription: shape({
      shortDescription: string,
    }),
    longDescription: shape({
      raw: string,
    }),
    parallaxMediaType: string,
    video: shape({
      file: shape({
        url: string,
      }),
    }),
    image: shape({
      gatsbyImageData: shape({
        images: shape({
          sources: arrayOf(
            shape({
              srcSet: string,
              sizes: string,
              type: string,
            }),
          ),
          fallback: shape({
            src: string,
            srcSet: string,
            sizes: string,
          }),
        }),
        layout: string,
        backgroundColor: string,
        width: number,
        height: number,
      }),
      file: shape({
        url: string,
      }),
    }),
    companyPurpose: arrayOf(
      shape({
        id: string,
        title: string,
        icon: shape({
          file: shape({
            url: string,
          }),
        }),
        description: shape({
          description: string,
        }),
      }),
    ),
  }),
}
