import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, layout, palette, spacing}) => ({
  container: {
    height: layout.header.height,
    position: 'fixed!important',
    top: 0,
    left: 0,
    borderBottom: `1px solid ${palette.background.gray}`,
    fontFamily: 'CamptonLight',
    backgroundColor: palette.background.default,
    zIndex: 20,
    width: '100vw',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > div': {
      maxWidth: 1300,
      width: '95%',
    },
    [breakpoints.down('sm')]: {
      height: layout.header.heightMobile,
    },
  },
}))

export default useStyles
