import {bool, shape, string} from 'prop-types'
import {Link} from 'gatsby'
import classNames from 'classnames'
import React from 'react'

import useStyles from './styles'

const LogoCorp = ({isModalOpen, logo, modal, routing}) => {
  const classes = useStyles()

  return (
    <Link
      to={routing}
      className={classNames(classes.logoKeyrus, {
        [classes.logoKeyrusModal]: isModalOpen && modal,
      })}
    >
      <img alt={logo.title} src={logo.file.url} loading="lazy" />
    </Link>
  )
}

export default LogoCorp

LogoCorp.propTypes = {
  isModalOpen: bool,
  logo: shape({
    url: string,
    title: string,
  }),
  modal: bool,
  routing: string,
}

LogoCorp.defaultProps = {
  isModalOpen: false,
  logo: '',
  modal: false,
  routing: '',
}
